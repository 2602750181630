<template>
  <v-container>
    <v-row class="d-flex text-center">
      <v-col col cols="12">
        <h2 class="display-2">
          Sistema de Gestión Mexicana de ASSA
        </h2>
        <hr class="blue darken-4 mt-2 mb-6" />
      </v-col>
    </v-row>
    <v-row class="d-flex justify-center">
      <v-col col xl="6" md="6" sm="12">
        <v-row class="d-flex justify-center">
          <v-col cols="6">
            <v-btn :to="{ name: 'Login' }" x-large color="indigo darken-4" size="x-large" height="72" min-width="164" block dark  class="strong float-right" >
              <v-icon left> mdi-login </v-icon>
              INGRESAR
            </v-btn>
          </v-col>
          <v-col cols="6">
            <v-btn :to="{ name: 'Register' }" block class="text-none mb-4 white--text" style="font-size: 20px" color="red darken-4" size="x-large" height="72" min-width="164" variant="flat">
              <v-icon left>mdi-newspaper-check</v-icon>
              Registrarse
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row class="d-flex justify-center">
      <v-col col xl="6" md="6" sm="12">
        <hr class="blue darken-4 mt-2 mb-6" />
      </v-col>
    </v-row>
    <v-row class="d-flex text-center">
      <v-col col cols="12">
        <h2 class="display-2">
          N O T I C I A S 
        </h2>
      </v-col>
    </v-row>
    <v-row class="d-flex justify-center">
      <v-col col xl="6" md="6" sm="12">
        <p class="text-justify">
          <strong>Lunes 17 de Junio 2024.</strong>
        </p>
      </v-col>
    </v-row>
    <v-row class="d-flex justify-center">
      <v-col col xl="6" md="6" sm="12">
        <v-img  src="https://assa.org.mx/wp-content/uploads/2024/06/BENEFICIARIOS_DE_LOS_RECURSOS_DE_LA_VENTA_DE_LOS_BIENES_INMUEBLES_GRUPO_MEXICANA.png"></v-img>
      </v-col>
    </v-row>
    <v-row class="d-flex justify-center">
      <v-col col xl="6" md="6" sm="12">
        <p class="text-justify">
          <strong>Martes 2 de Abril 2023.</strong>
        </p>
      </v-col>
    </v-row>
    <v-row class="d-flex justify-center">
      <v-col col xl="6" md="6" sm="12">
        <v-img  src="https://cdn.assa.mx/circulares/mexicana/informacion-mexicana-de-aviacion-aerovias-caribe-y-mexicana-inter.png"></v-img>
      </v-col>
    </v-row>
    <v-row class="d-flex justify-center">
      <v-col col xl="6" md="6" sm="12">
        <p class="text-justify">
          <strong>Lunes 30 de Octubre 2023.</strong>
        </p>
      </v-col>
    </v-row>
    <v-row class="d-flex justify-center">
      <v-col col xl="6" md="6" sm="12">
        <v-img  src="https://cdn.assa.mx/circulares/sg/SG-REF-070-2023.png"></v-img>
      </v-col>
    </v-row>
    <v-row class="d-flex justify-center">
      <v-col col xl="6" md="6" sm="12">
        <p class="text-justify">
          <strong>Martes 19 de Septiembre 2023.</strong>
        </p>
      </v-col>
    </v-row>
    <v-row class="d-flex justify-center">
      <v-col col xl="6" md="6" sm="12">
        <v-img  src="https://cdn.assa.mx/circulares/sg/SG-REF-065-2023.jpeg"></v-img>
      </v-col>
    </v-row>
    <v-row class="d-flex justify-center">
      <v-col col xl="6" md="6" sm="12">
        <p class="text-justify">
          <strong>Jueves 7 de Septiembre 2023.</strong>
        </p>
      </v-col>
    </v-row>
    <v-row class="d-flex justify-center">
      <v-col col xl="6" md="6" sm="12">
        <v-img  src="https://cdn.assa.mx/circulares/sg/SG-REF-062-2023-.png"></v-img>
      </v-col>
    </v-row>
    <v-row class="d-flex justify-center">
      <v-col col xl="6" md="6" sm="12">
        <p class="text-justify">
          <strong>Martes 4 de Septiembre 2023.</strong>
        </p>
      </v-col>
    </v-row>
    <v-row class="d-flex justify-center">
      <v-col col xl="6" md="6" sm="12">
        <v-img  src="https://cdn.assa.mx/circulares/sg/sg-ref-058-2023.png"></v-img>
      </v-col>
    </v-row>
    <v-row class="d-flex justify-center">
      <v-col col xl="6" md="6" sm="12">
        <p class="text-justify">
          <strong>Martes 29 de Agosto 2023.</strong>
        </p>
      </v-col>
    </v-row>
    <v-row class="d-flex justify-center">
      <v-col col xl="6" md="6" sm="12">
        <v-img  src="https://cdn.assa.mx/circulares/sg/sg-ref-055-2023.jpeg"></v-img>
      </v-col>
    </v-row>
    <v-row class="d-flex justify-center">
      <v-col col xl="6" md="6" sm="12">
        <v-img  src="https://cdn.assa.mx/circulares/sg/sg-ref-054-2023.jpeg"></v-img>
      </v-col>
    </v-row>
    <v-row class="d-flex justify-center">
      <v-col col xl="6" md="6" sm="12">
        <p class="text-justify">
          <strong>Martes 22 de Agosto 2023.</strong>
        </p>
      </v-col>
    </v-row>
    <v-row class="d-flex justify-center">
      <v-col col xl="6" md="6" sm="12">
        <v-img  src="https://cdn.assa.mx/circular/circular-mexicana/dispersion_4.jpeg"></v-img>
      </v-col>
    </v-row>
    <v-row class="d-flex justify-center">
      <v-col col xl="6" md="6" sm="12">
        <v-img  src="https://cdn.assa.mx/circulares/sg/sg-ref-052-2023.jpeg"></v-img>
      </v-col>
    </v-row>
    <v-row class="d-flex justify-center">
      <v-col col xl="6" md="6" sm="12">
        <p class="text-justify">
          <strong>Jueves 17 de Agosto 2023.</strong>
        </p>
      </v-col>
    </v-row>
    <v-row class="d-flex justify-center">
      <v-col col xl="6" md="6" sm="12">
        <v-img  src="https://cdn.assa.mx/circular/circular-mexicana/dispercion_3.jpeg"></v-img>
      </v-col>
    </v-row>
    <v-row class="d-flex justify-center">
      <v-col col xl="6" md="6" sm="12">
        <hr class="blue darken-4 mt-2 mb-6" />
      </v-col>
    </v-row>
    <v-row class="d-flex justify-center">
      <v-col col xl="6" md="6" sm="12">
        <p class="text-justify">
          En las entidades federativas que se señalan a continuación, se entregarán los billetes de deposito a través de la Junta Federal que se indique para cada entidad, y posteriormente podrán cobrar dichos billetes en la Sucursal de Banco Bienestar que les corresponda y que se indicará en su momento.
        </p>
      </v-col>
    </v-row>
    <v-row class="d-flex justify-center">
      <v-col col xl="6" md="6" sm="12">
        <v-img  src="https://cdn.assa.mx/circular/circular-mexicana/CM-Dispersion-1.png"></v-img>
      </v-col>
    </v-row>
    <v-row class="d-flex justify-center">
      <v-col col xl="6" md="6" sm="12">
        <v-img src="https://cdn.assa.mx/circular/circular-mexicana/CM-Dispersion-2.png"></v-img>
      </v-col>
    </v-row>
    <v-row class="d-flex justify-center">
      <v-col col xl="6" md="6" sm="12">
        <v-img src="https://cdn.assa.mx/circular/circular-mexicana/CM-Dispersion-3.png"></v-img>
      </v-col>
    </v-row>
    <v-row class="d-flex justify-center">
      <v-col col xl="6" md="6" sm="12">
        <v-img src="https://cdn.assa.mx/circular/circular-mexicana/CM-Dispersion-4.png"></v-img>
      </v-col>
    </v-row>
    <v-row class="d-flex justify-center">
      <v-col col xl="6" md="6" sm="12">
        <v-img src="https://cdn.assa.mx/circular/circular-mexicana/CM-Dispersion-5.png"></v-img>
      </v-col>
    </v-row>
    <v-row class="d-flex justify-center">
      <v-col col xl="6" md="6" sm="12">
        <v-img src="https://cdn.assa.mx/circular/circular-mexicana/CM-Dispersion-6.png"></v-img>
      </v-col>
    </v-row>
    <v-row class="d-flex justify-center">
      <v-col col xl="6" md="6" sm="12">
        <v-img src="https://cdn.assa.mx/circular/circular-mexicana/CM-Dispersion-7.png"></v-img>
      </v-col>
    </v-row>
    
  </v-container>
</template>
<script>
//Componentes
import GlobalAlerts from "@/components/global/_Alerts.vue";


//Models
import User from "@/models/user";



export default {
  name: "Landing",
  components: {
    GlobalAlerts
  },
  data() {
    return {
      loader: null,
      loading: false
    };
  },
  watch: {
   
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
  },
  mounted() {

  },
  methods: {
    
  }
};
</script>