import axios from "axios";
import authHeader from "./auth-header";

const apiUrl = process.env.VUE_APP_ENDPOINT;
const contentType = "application/json";
const apiToken = process.env.VUE_APP_TOKEN_API;
const bearerToken = JSON.parse(localStorage.getItem("user_session"));

class wsAuthentication {
  registerFlightAssistant(data) {
    return axios.post(apiUrl + "/register/flight-assistant/", data, {
      headers: { "Content-Type": contentType, Authorization: apiToken },
    });
  }

  registerValidate(data) {
    return axios.post(apiUrl + "/register/validate/", data, {
      headers: { "Content-Type": contentType, Authorization: apiToken },
    });
  }

  registerRecovery(data) {
    return axios.post(apiUrl + "/password/validate/", data, {
      headers: { "Content-Type": contentType, Authorization: apiToken },
    });
  }

  registerComplete(data) {
    return axios.post(apiUrl + "/register/complete/", data, {
      headers: {
        "Content-Type": contentType,
        Authorization: data.token,
      },
    });
  }

  passwordRecovery(data) {
    return axios.post(apiUrl + "/authentication/password/recover/", data, {
      headers: { "Content-Type": contentType, Authorization: apiToken },
    });
  }
}

export default new wsAuthentication();
