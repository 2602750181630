<template>
  <div>
    <v-row class="">
      <v-col>
        <h1>Laborales</h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols md="12">
        <GlobalAlerts v-if="common.activate === true" 
                      style="text-align: justify;"
                      :cfgColorBg="this.common.alert == 'warning' ? 'amber darken-3' : 'light-green darken-2'"
                      :cfgIcon="this.common.alert == 'warning' ? 'mdi-alert-outline' : 'mdi-information-outline'"
                      cfgProminent="true"
                      :cfgMessage="common.message"/>
      </v-col>
    </v-row>

    <v-form ref="form" v-model="valid" lazy-validation>
      <v-row>
        <v-col cols md="12">
          <v-autocomplete
            :items="empresa"
            item-text="nombre"
            item-value="clave"
            filled
            label="Empresa"
            :rules="rules.required"
            v-model="currentSession.empresa.empresa"
          ></v-autocomplete>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols md="6">
          <v-autocomplete
            :items="situacion"
            item-text="nombre"
            item-value="clave"
            filled
            label="Situación"
            :rules="rules.required"
            v-model="currentSession.empresa.situacion"
          ></v-autocomplete>
        </v-col>
        <v-col cols md="6">
          <v-text-field
            filled
            :rules="rules.required"
            label="Numero de empleado:"
            v-model="currentSession.empresa.numeroEmpleado"
          />
        </v-col>
      </v-row>
      <v-row v-if="currentUser.solicitud.estatus !== 'ENVIADA'">
        <v-col cols md="12">
          <p class="text-justify"><strong>Nota:</strong> Si realizas alguna actualización en la sección, es necesario que presiones el boton verde con la leyenda de Guardar. En caso de no realizar el procedimiento mencionado los datos se perderán.</p>
        </v-col>
      </v-row>
      <v-row v-if="currentUser.solicitud.estatus !== 'ENVIADA'">
        <v-col cols md="4"></v-col>
        <v-col cols md="4">
          <v-btn @click="saveRegister()" dark x-large color="green darken-1" block>
          <v-icon>mdi-content-save</v-icon>
          Guardar
        </v-btn>
        <br/>
        </v-col>
        <v-col cols md="4"></v-col>
      </v-row>
      <v-row>
        <v-col cols md="12">
          <GlobalAlerts v-if="common.activate === true" 
                        style="text-align: justify;"
                        :cfgColorBg="this.common.alert == 'warning' ? 'amber darken-3' : 'light-green darken-2'"
                        :cfgIcon="this.common.alert == 'warning' ? 'mdi-alert-outline' : 'mdi-information-outline'"
                        cfgProminent="true"
                        :cfgMessage="common.message"/>
        </v-col>
      </v-row>
    </v-form>
    <v-row>
      <v-col cols md="12" class="grey lighten-4 my-4 pa-4">
        <v-col cols md="12">
          <p class="text-justify"><strong>Nota:</strong> Los botones de la siguiente sección es para poder navegar entre las diversas pantallas de la aplicaciòn.</p>
        </v-col>
        <v-btn to="/flight-attendant/home" dark x-large color="blue darken-1" class="ma-2 float-right">
          <v-icon>mdi-skip-next</v-icon>
          Siguiente
        </v-btn>
        <v-btn to="/flight-attendant/personal-data" dark x-large color="blue darken-1" class="ma-2 float-right">
          <v-icon>mdi-skip-previous</v-icon>
          Anterior
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import wsFlightAttendant from "@/services/flight-assistant";
import GlobalAlerts from "@/components/global/_Alerts.vue";
import jsonEstados from "@/services/catalogos/estados.json";
import jsonEmpresa from "@/services/catalogos/empresa.json";
import jsonSituacion from "@/services/catalogos/situacion.json";

export default {
  name: "FlightAttendantDatosLaboralesComponent",

  components: {
    GlobalAlerts
  },

  data: () => ({
    tab: null,
    valid: true,
    estados: jsonEstados,
    situacion: jsonSituacion,
    empresa: jsonEmpresa,
    completeRegister: false,
    rules: {
      required: [(value) => !!value || "El campo es obligatorio."],
      counter: (value) =>
        value.length === this.losnummerLength ||
        `solo se permiten ${this.losnummerLength} caracteres.`,
    },
    common: {
        activate: false,
        alert:'',
        message:'',
        code: ''
      }
  }),

  mounted() {},

  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
    currentSession() {
      var session = JSON.parse(this.$localStorage.get("data_user"));
      return session;
    },
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
  },

  methods: {
    saveRegister() {
      if (this.$refs.form.validate()) {
        var data = {
          usuario: {
            id: this.currentSession.usuario.id,
            usuario: this.currentSession.usuario.usuario,
          },
          sobrecargo: {
            id: this.currentSession.sobrecargo.id,
            nombre: this.currentSession.sobrecargo.nombre,
            primerApellido: this.currentSession.sobrecargo.primerApellido,
            segundoApellido: this.currentSession.sobrecargo.segundoApellido,
            curp: this.currentSession.sobrecargo.curp,
            correoElectronico: this.currentSession.sobrecargo.correoElectronico,
            telefono: this.currentSession.sobrecargo.telefono,
            telefonoMobil: this.currentSession.sobrecargo.telefonoMobil,
            avisoPriv: this.currentSession.sobrecargo.avisoPriv,
            finado: this.currentSession.sobrecargo.finado,
            pais:this.currentSession.sobrecargo.pais,
            extranjero:this.currentSession.sobrecargo.extranjero,
            calle:this.currentSession.sobrecargo.calle,
            numExt:this.currentSession.sobrecargo.numExt,
            numInt:this.currentSession.sobrecargo.numInt,
            colonia:this.currentSession.sobrecargo.colonia,
            codigoPostal:this.currentSession.sobrecargo.codigoPostal,
            ciudad:this.currentSession.sobrecargo.ciudad,
            estado:this.currentSession.sobrecargo.estado
          },
          empresa: {
            empresa: this.currentSession.empresa.empresa,
            numeroEmpleado: this.currentSession.empresa.numeroEmpleado,
            situacion: this.currentSession.empresa.situacion,
          },
          solicitud: {
            id: this.currentSession.solicitud.id,
            estatus: this.currentSession.solicitud.estatus,
            folio: null,
          }
        };
        wsFlightAttendant.updateFlightAssistantEmployment(data).then((response) => {
            if(response.status == 200){
              switch(response.data.codigo){
                case "1000":
                  localStorage.removeItem("user");
                  localStorage.removeItem("data_user");
                  localStorage.setItem("user", JSON.stringify(response.data));
                  localStorage.setItem("data_user", JSON.stringify(response.data));
                  this.common.activate = true;
                  this.common.alert = 'info';
                  this.common.message = 'Los datos han sido actualizados satisfactoriamente.';
                  this.common.code = '0';
                  break;
                case "2":
                  this.common.activate = true;
                  this.common.alert = 'info';
                  this.common.message = response.data.mensaje;
                  this.common.code = '0';
                  break;
                case "-1":
                  this.common.activate = true;
                  this.common.alert = 'warning';
                  this.common.message = 'En estos momentos el sistema se encuentra en mantenimiento, pronto estaremos de regreso.';
                  this.common.code = '0';
                  localStorage.removeItem("ipAddress");
                  localStorage.removeItem("estatus");
                  localStorage.removeItem("folio");
                  localStorage.removeItem("token");
                  localStorage.removeItem("user");
                  localStorage.removeItem("data_user");
                  localStorage.removeItem("data_curp");
                  localStorage.removeItem("data_pasaporte");
                  localStorage.removeItem("data_credencial");
                  localStorage.removeItem("data_ine");
                  localStorage.removeItem("data_assa");
                  this.$router.push("/");
                  break;
                case "100":
                  this.common.activate = true;
                  this.common.alert = 'warning';
                  this.common.message = 'Enviar un correo electrónico a sigema@assa.mx. Por favor incluir los siguientes datos: correo electrónico, curp y el siguiente codigo DG-100, gracias.';
                  this.common.code = '0';
                  break;
              }
            } else {
              this.common.activate = true;
              this.common.alert = 'warning';
              this.common.message = 'En estos momentos el sistema se encuentra en mantenimiento, pronto estaremos de regreso.';
              this.common.code = '0';
            }
          },
          (error) => {
            if (error.response) {
              // La respuesta fue hecha y el servidor respondió con un código de estado, que esta fuera del rango de 2xx
              if(error.response.status == 403){
                this.common.activate = true;
                this.common.alert = 'warning';
                this.common.message = 'En estos momentos el sistema se encuentra en mantenimiento, pronto estaremos de regreso.';
                this.common.code = '-1';
                localStorage.removeItem("ipAddress");
                localStorage.removeItem("estatus");
                localStorage.removeItem("folio");
                localStorage.removeItem("token");
                localStorage.removeItem("user");
                localStorage.removeItem("data_user");
                localStorage.removeItem("data_curp");
                localStorage.removeItem("data_pasaporte");
                localStorage.removeItem("data_credencial");
                localStorage.removeItem("data_ine");
                localStorage.removeItem("data_assa");
                this.$router.push("/");
              } else {
                this.common.activate = true;
                this.common.alert = 'warning';
                this.common.message = 'En estos momentos el sistema se encuentra en mantenimiento, pronto estaremos de regreso.';
                this.common.code = '-1';
              }
              console.log(error.response.data);
              console.log(error.response.status);
              console.log(error.response.headers);
            } else if (error.request) {
              // La petición fue hecha pero no se recibió respuesta
              // `error.request` es una instancia de XMLHttpRequest en el navegador y una instancia de
              // http.ClientRequest en node.js
              console.log('Error.Request');
              console.log(error.request);
              this.common.activate = true;
              this.common.alert = 'warning';
              this.common.message = 'En estos momentos el sistema se encuentra en mantenimiento, pronto estaremos de regreso.';
              this.common.code = '-2';
            } else {
              // Algo paso al preparar la petición que lanzo un Error
              console.log('Error');
              console.log(error.message);
              this.common.activate = true;
              this.common.alert = 'warning';
              this.common.message = 'En estos momentos el sistema se encuentra en mantenimiento, pronto estaremos de regreso.';
              this.common.code = '-3';
            }
          }
        );
      }
    },

  }
};
</script>
