<template>
  <v-app>
    <v-app-bar app color="blue darken-4" dark>
      <section class="d-flex align-center">
        <a href="/">
          <v-img alt="ASSA DE MÉXICO" class="shrink mr-8" contain min-width="175" src="@/assets/img/logo-assa-movil-light.png" width="175"/>
        </a>
        <h1 class="hidden-sm-and-down white--text text-h5 text-shadow">
          {{ meta.appName }}
          <span class="blue-grey--text text--lighten-4">{{
            meta.appVersion
          }}</span>
        </h1>
      </section>
      <v-spacer></v-spacer>
      <MenuPublic v-if="!currentUser || currentUser.codigo === '100'"></MenuPublic>
      <Menu v-if="currentUser && currentUser.codigo === '1000'"></Menu>
    </v-app-bar>
    <!--body-->
    <v-main class="my-7">
      <router-view />
    </v-main>
    <!--body-->
    <v-footer color="red darken-4" padless fixed class="footer-glass white--text">
      <v-row class="py-3 text-center">
        <v-col cols="12">
          <small>&copy; {{ new Date().getFullYear() }} ASSA DE MÉXICO</small>
        </v-col>
      </v-row>
    </v-footer>
  </v-app>
</template>
<script>
import Menu from "@/components/menu/Menu.vue";
import MenuPublic from "@/components/menu/MenuPublic.vue";
export default {
  name: "App",

  components: {
    Menu,
    MenuPublic,
  },

  data: () => ({
    user_role: "", // Declarar el metodo
    links: [],
    menuRegistro: false,
    meta: {
      appName: process.env.VUE_APP_NAME,
      appVersion: process.env.VUE_APP_VERSION,
    },
  }),

  computed: {
    /* Comprobamos el inicio de la sesion por medio del Store */
    currentUser() {
      return this.$store.state.auth.user;
    },
    /* Comprobamos el inicio de la sesion por medio del Store */
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
    session() {
      return false;
    },
  },

  mounted() {
    document.title = "SISTEMA DE GESTION MEXICANA DE LA ASSA";
  },

  methods: {},
};
</script>
