<template>
  
  <section>
    <!--
    <v-btn :to="{ name: 'Landing' }" text>
      <v-icon>mdi-home</v-icon>
      Inicio
    </v-btn>
    <v-btn :to="{ name: 'Login' }" text>
      <v-icon>mdi-logout</v-icon>
      Iniciar sesión
    </v-btn>
    -->
  </section>
  
</template>
<script>
export default {
  name: "MenuPublic",

  components: {},

  data: () => ({}),

  mounted() {},

  computed: {},

  methods: {},
};
</script>
