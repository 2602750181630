<template>
  <v-container>
    <v-row class="d-flex justify-center">
      <h1 class="display-3">Registro</h1>
    </v-row>
    <v-row class="d-flex justify-center">
      <v-col col xl="5" md="6" sm="12">
        <p class="text-justify">
          <strong class="display-1">Procedimiento</strong>
        </p>
        <p class="text-justify">
          <strong>1.</strong> Captura los datos solicitados, recuerda que estos serán proporcionados a las autoridades correspondientes. 
        </p>
        <p class="text-justify">
          <strong>2.</strong> Proporciona un correo que consultes constantemente para recibir notificaciones. <strong>¡IMPORTANTE!</strong> Revisa tu <strong>Bandeja de Entrada</strong> y <strong>la bandeja de spam</strong>.
        </p>
        <p class="text-justify">
          <strong>3.</strong>  Después de capturar tus datos y de presionar el botón <strong>GUARDAR</strong> recibirás un correo electrónico con una contraseña aleatoria que podrás modificar en cuanto ingreses al sistema.
        </p>
      </v-col>
    </v-row>
    <v-row class="d-flex justify-center">
      <v-col col xl="5" md="6" sm="12">
        <!--
        <GlobalAlerts
          v-if="register.codigo === '1'"
          cfgColorBg="light-green darken-2"
          cfgIcon="mdi-check-all"
          cfgProminent="true"
          :cfgMessage="register.mensaje"
        />
        <GlobalAlerts
          v-if="register.codigo === '3'"
          cfgColorBg="amber darken-3"
          cfgIcon="mdi-information"
          cfgProminent="true"
          :cfgMessage="register.mensaje"
        />
        -->
        

        <v-form  ref="form" v-model="valid" lazy-validation v-if="register.codigo != '1'">
          <v-text-field
            filled
            label="Nombre(s)"
            v-model="dataForm.nombre"
            :rules="rules.required"
          />
          <v-text-field
            filled
            label="Primer apellido"
            v-model="dataForm.primerApellido"
            :rules="rules.required"
          />
          <v-text-field
            filled
            label="Segundo apellido"
            v-model="dataForm.segundoApellido"
            :rules="rules.required"
          />
          <p class="text-justify">
            <strong>Es de suma importancia que captures correctamente tu CURP (Clave Única de Registro de Población), está compuesta de 18 carácteres alfanuméricos.</strong> 
          </p>
          <v-text-field
            filled
            label="CURP" :counter="18" :maxlength="18" 
            v-model="dataForm.curp" 
            :rules="rules.required"
          />
          <v-text-field
            filled
            label="Correo electrónico"
            v-model="dataForm.correoElectronico"
            :rules="rules.email"
          />
          <v-text-field
            filled
            label="Repite correo electrónico"
            :rules="emailConfirm"
          />

          <div class="">
            <v-btn x-large  :disabled='isDisabled' color="blue darken-4" dark class="float-right" @click="saveRegister()">
              <v-icon dark> mdi-content-save-check-outline </v-icon>
              GUARDAR
            </v-btn>
          </div>
        </v-form>
        <GlobalAlerts v-if="common.activate === true" 
                      style="text-align: justify;"
                      :cfgColorBg="this.common.alert == 'warning' ? 'amber darken-3' : 'light-green darken-2'"
                      :cfgIcon="this.common.alert == 'warning' ? 'mdi-alert-outline' : 'mdi-information-outline'"
                      cfgProminent="true"
                      :cfgMessage="common.message"/>
        <v-btn :to="{ name: 'Login' }" v-if="register.codigo == '1'"
               block
               class="text-none mb-4 white--text" style="font-size: 20px;"
               color="indigo darken-4"
               size="x-large" height="72"
               min-width="164"
               variant="flat">
          <v-icon left> mdi-login </v-icon>
          Iniciar Sesión
        </v-btn>
      </v-col>
    </v-row>

    <v-row class="mb-10">
      <pre></pre>
    </v-row>
  </v-container>
</template>

<script>
import GlobalAlerts from "@/components/global/_Alerts.vue";
import wsAuthentication from "@/services/authentication";

export default {
  name: "Register",

  components: {
    GlobalAlerts,
  },

  data() {
    return {
      loader: null,
      loading: false,
      message: "",
      valid: true,
      showpass: false,
      errorStatus: "",
      isDisabled:false,
      register: {},
      common: {
        activate: false,
        alert:'',
        message:'',
        code: ''
      },
      dataForm: {
        nombre: "",
        primerApellido: "",
        segundoApellido: "",
        curp: "",
        correoElectronico: "",
      },

      rules: {
        required: [(value) => !!value || "El dato es obligatorio."],
        counter: value => value.length === this.losnummerLength || `solo se permiten ${this.losnummerLength} caracteres.`,
        email: [
          (v) => !!v || "El correo electrónico obligatorio",
          (v) => /.+@.+/.test(v) || "Con es un correo valido",
        ],
        password: [(v) => !!v || "La Contraseña es obligatoria"],
      },
    };
  },

  watch: {},

  computed: {
    emailConfirm() {
      return [
        (v) =>
          v === this.dataForm.correoElectronico || "Los correos no coinciden",
      ];
    },
  },

  mounted() {
    localStorage.removeItem("user_session");
    localStorage.removeItem("ipAddress");
    localStorage.removeItem("estatus");
    localStorage.removeItem("folio");
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    localStorage.removeItem("data_user");
    localStorage.removeItem("data_curp");
    localStorage.removeItem("data_pasaporte");
    localStorage.removeItem("data_credencial");
    localStorage.removeItem("data_ine");
    localStorage.removeItem("data_assa");
    document.title = "SIGEMA - REGISTRO";    
  },

  methods: {
    validar() {
      this.$refs.form.validate();
      console.log(
        "🚀 ~ file: Register.vue:158 ~ validar ~ this.$refs.form.validate():",
        this.$refs.form.validate()
      );
    },

    saveRegister() {
      if (this.$refs.form.validate()) {
        this.isDisabled = true;
        var data = {
          nombre: this.dataForm.nombre,
          primerApellido: this.dataForm.primerApellido,
          segundoApellido: this.dataForm.segundoApellido,
          curp: this.dataForm.curp,
          correoElectronico: this.dataForm.correoElectronico,
        };
        wsAuthentication.registerFlightAssistant(data).then(
          (response) => {
            if(response.status == 200){
              this.register = response.data;
              switch(response.data.codigo){
                case "1":
                  this.common.activate = true;
                  this.common.alert = 'info';
                  this.common.message = this.register.mensaje;
                  this.common.code = '0';
                  break;
                case "3":
                  this.common.activate = true;
                  this.common.alert = 'warning';
                  this.common.message =  this.register.mensaje;
                  this.common.code = '0';
                  break;
              }
            } else {
              this.common.activate = true;
              this.common.alert = 'warning';
              this.common.message = 'En estos momentos el sistema se encuentra en mantenimiento, pronto estaremos de regreso.';
              this.common.code = '0';
            }
          },
          (error) => {
            if (error.response) {
            // La respuesta fue hecha y el servidor respondió con un código de estado, que esta fuera del rango de 2xx
              if(error.response.status == 403){
                this.common.activate = true;
                this.common.alert = 'warning';
                this.common.message = 'En estos momentos el sistema se encuentra en mantenimiento, pronto estaremos de regreso.';
                this.common.code = '-1';
              } else {
                this.common.activate = true;
                this.common.alert = 'warning';
                this.common.message = 'En estos momentos el sistema se encuentra en mantenimiento, pronto estaremos de regreso.';
                this.common.code = '-1';
              }
              console.log(error.response.data);
              console.log(error.response.status);
              console.log(error.response.headers);
            } else if (error.request) {
              // La petición fue hecha pero no se recibió respuesta
              // `error.request` es una instancia de XMLHttpRequest en el navegador y una instancia de
              // http.ClientRequest en node.js
              console.log('Error.Request');
              console.log(error.request);
              this.common.activate = true;
              this.common.alert = 'warning';
              this.common.message = 'En estos momentos el sistema se encuentra en mantenimiento, pronto estaremos de regreso.';
              this.common.code = '-2';
            } else {
              // Algo paso al preparar la petición que lanzo un Error
              console.log('Error');
              console.log(error.message);
              this.common.activate = true;
              this.common.alert = 'warning';
              this.common.message = 'En estos momentos el sistema se encuentra en mantenimiento, pronto estaremos de regreso.';
              this.common.code = '-3';
            }
            this.isDisabled = false;
          }
        );
      }
    },
  },
};
</script>
