<template>
  <v-container class="">
    <v-row>
        <v-col cols="12" sm="10">
            <h2 class="display-1"><v-icon class="display-1">mdi-home</v-icon>Sobrecargo</h2>
        </v-col>
        <v-col cols="12" sm="2" style="text-align: right;">
            <v-btn x-large block color="red darken-4" class="white--text" @click="logOut()" >
                <v-icon>mdi-logout</v-icon><strong>SALIR</strong>
            </v-btn>
        </v-col>
        <v-col cols="12">
            <hr class="red"/>
            <v-breadcrumbs :items="breadcrums" divider="/"></v-breadcrumbs>
        </v-col>
    </v-row>
    <v-row>
        <v-col cols="12">
            <p class="text-lg-right"> <strong>{{ nombre }}</strong> <v-icon x-large>mdi-account</v-icon></p>
        </v-col>
    </v-row>
    <v-row>
      <v-col cols md="12">
        <v-col cols md="12">
          <p class="text-center"><strong>Lee con mucha atención las instrucciones que se encuentran dentro del SIGEMA. </strong></p>
        </v-col>
      </v-col>
    </v-row>
    <v-row class="d-flex justify-center">
      <v-col col xl="6" md="6" sm="12">
        <GlobalAlerts v-if="common.activate === true" 
                      style="text-align: justify;"
                      :cfgColorBg="this.common.alert == 'warning' ? 'amber darken-3' : 'light-green darken-2'"
                      :cfgIcon="this.common.alert == 'warning' ? 'mdi-alert-outline' : 'mdi-information-outline'"
                      cfgProminent="true"
                      :cfgMessage="common.message"/>
      </v-col>
    </v-row>
    <v-row v-if="currentUser.solicitud != null && currentUser.solicitud.estatus !== 'ENVIADA'">
      <!-- 
      <v-col cols md="3" >
        <v-btn x-large  color="red darken-4" dark class="float-right" @click="panelContraseña = true" block>
          <v-icon dark> mdi-send-secure </v-icon>
          Modificar contraseña
        </v-btn>
      </v-col>
      -->
      <v-col cols md="3" >
        <v-sheet >
          <strong>Estatus Solicitud:</strong><br/>{{currentUser.solicitud.estatus}}
        </v-sheet>
      </v-col>
      <v-col cols md="3" class="text-center">
        <!--
        <strong>Presiona el botón Enviar una vez finalizada la captura.</strong>
        -->
      </v-col>
      <v-col cols md="3" >
        <!--
        <v-btn x-large  color="green darken-4" dark class="float-right" @click="enviar()" block>
          <v-icon dark> mdi-send-secure </v-icon>
          ENVIAR
        </v-btn>
        <v-btn x-large  color="green darken-4" dark class="float-right" @click="logOut()" block>
          <v-icon dark> mdi-send-secure </v-icon>
          ENVIAR
        </v-btn>
        -->
        
      </v-col>
    </v-row>
    <v-row v-if="currentUser.solicitud.estatus === 'ENVIADA'">
      <v-col cols md="3" >
        <v-btn x-large  color="red darken-4" dark class="float-right" @click="panelContraseña = true" block>
          <v-icon dark> mdi-send-secure </v-icon>
          Modificar contraseña
        </v-btn>
      </v-col>
      <v-col cols md="3" >
        <v-sheet >
          <strong>Estatus Solicitud:</strong><br/><strong style="color: #FF0000;font-size: 20px;;">{{currentUser.solicitud.estatus}}</strong>
        </v-sheet>
      </v-col>
    </v-row>
    <v-form v-if="panelContraseña" ref="form" v-model="valid" lazy-validation class="grey lighten-4 my-4 pa-4">
      <v-row class="">
        <v-col>
          <h1>Cambio de contraseña</h1>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols md="3">
          <v-text-field
            filled
            label="Contraseña"
            v-model="set.password"
            :rules="rules.required"
            :append-icon="showpass ? 'mdi-eye' : 'mdi-eye-off'"
            :type="showpass ? 'text' : 'password'"
            hint="Coloca al menos 8 caracteres"
            counter
            @click:append="showpass = !showpass"
          />
        </v-col>
        <v-col cols md="3">
          <v-text-field
            filled
            label="Repetir contraseña"
            :rules="passwordConfirm"
            :append-icon="showpass ? 'mdi-eye' : 'mdi-eye-off'"
            :type="showpass ? 'text' : 'password'"
            hint="Coloca al menos 8 caracteres"
            counter
            @click:append="showpass = !showpass"
          />
        </v-col>
        <v-col cols md="2">
          <v-btn @click="resetPassword()" block dark x-large color="green darken-1" class="ma-2 float-right">
            Aceptar
          </v-btn>
        </v-col>
        <v-col cols md="2" >
          <v-btn @click="panelContraseña = false" block dark x-large color="gray darken-1" class="ma-2 float-right">
            Cancelar
          </v-btn>
        </v-col>
        <v-col cols md="2" ></v-col>
      </v-row>
    </v-form>
   
    <v-row>
      <v-col cols md="3">
        <v-btn dark x-large color="primary"  to="/flight-attendant/personal-data" block>
          Generales
        </v-btn>
      </v-col>
      <v-col cols md="3">
        <v-btn dark x-large color="primary" to="/flight-attendant/employment-data" block>
          Laborales
        </v-btn>
      </v-col>
      <v-col cols md="3">
        <v-btn dark x-large color="primary" to="/flight-attendant/home" block>
          Residencia
        </v-btn>
      </v-col>
      <v-col cols md="3">
        <v-btn dark x-large color="primary" to="/flight-attendant/documents" block>
          Documentación
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols md="12" class="rounded-lg grey lighten-5 mt-6 pa-8 elevation-15">
        <router-view></router-view>
      </v-col>
    </v-row>
  </v-container>
</template>



<script>
import wsFlightAttendant from "@/services/flight-assistant";
import FlightAttendantDatosPersonalesComponent from "@/components/FlightAttendant/DatosPersonales";
import FlightAttendantDatosLaboralesComponent from "@/components/FlightAttendant/DatosLaborales";
import FlightAttendantDocumentosComponent from "@/components/FlightAttendant/Documentos";
import jsonBusqueda from "@/services/catalogos/busqueda.json";


import GlobalAlerts from "@/components/global/_Alerts.vue";
import User from "@/models/user";

export default {
  name: "FlightAttendant",

  components: {
    GlobalAlerts,
    FlightAttendantDatosPersonalesComponent,
    FlightAttendantDatosLaboralesComponent,
    FlightAttendantDocumentosComponent
  },

  data() {
    return  {
      breadcrums: [
        {
          text: 'Dashboard',
          disabled: false,
          href: '/dashboard',
        },
        {
          text: 'Sobrecargo',
          disabled: true,
          href: '/flight-attendant/personal-data',
        }
      ],
      nombre: '',
      valid: true,
      userAdmin: false,
      busquedaList: jsonBusqueda,
      showpass: false,
      rules: {
        password: [(v) => !!v || "La Contraseña es obligatoria"],
      },
      tab: null,
      
      panelContraseña: false,
      completeRegister: false,
      persona: "-",
      user: new User("", ""),
      loader: null,
      loading: false,
      message: "",
      
      showpass: false,
      errorStatus: "",
      currentImage: undefined,
      previewImage: undefined,
      uploadFile: false,
      alertWarning: false,
      loading: false,
      loaded: false,
      toggle_exclusive: 1,
      common: {
        activate: false,
        alert:'',
        message:'',
        code: ''
      },

      documentos: {
        documentoIdentificacion: [],
        documentoCurp: [],
        documentoCredencialEmpresa: [],
        documentoCredencialAssa: [],
        documentoPasaporte: []
      },set: {
        password: "",
      },

      dataForm: {
        nombre: "",
        primerApellido: "",
        segundoApellido: "",
        correoElectronico: "",
        curp: "",
        telefonoContacto: "",
        telefonoMovil: "",
        nombreEmpresa: "",
        situacion: "",
        numeroEmpleado: "",
        avisoPrivacidad: 0,
        documentoIdentificacion: [],
        documentoCurp: [],
        documentoCredencialEmpresa: [],
        documentoCredencialAssa: [],
        documentoPasaporte: []
      },
      rules: {
        required: [(value) => !!value || "El campo es obligatorio."],
        uploadFileSize: [
          (value) =>
            !value ||
            value.size < 2000000 ||
            "¡El documento debe ser menor a 2 MB!",
        ],
      },
      //Busqueda
      criterio:null,
      buscarCadena:'',
      busquedaCadena:'',
      //Tabla
      
        //
        expanded: [],
        singleExpand: false,
        dessertHeaders: [
          {
            text: 'CURP',
            align: 'start',
            sortable: false,
            value: 'curp',
          },
          { text: 'Correo electrónico', value: 'correoElectronico' },
          { text: 'Primer apellido', value: 'primerApellido' },
          { text: 'Segundo apellido', value: 'segundoApellido' },
          { text: 'Nombre(s)', value: 'nombre' },
          { text: 'Fecha de registro', value: 'fechaRegistro' },
          { text: 'Fecha modificación', value: 'fechaModificacion' },
          { text: '', value: 'data-table-expand' },
        ],
        desserts: [
          
          /*
          {
            curp: 'GAGG831126HDFRRL06',
            correoElectronico: 'isramemo@gmail.com',
            nombre: 'Guillermo Israel',
            primerApellido: 'García',
            segundoApellido: 'Guerrero',
            telefonoCasa:'55517737',
            telefonoMovil:'5554073573',
            finado:0,
            fechaRegistro: '14/07/2023 23:11:12',
            fechaModificacion: '14/07/2023 23:11:12',
            empresa:{
              empresa:'Mexicana de Aviación',
              numeroEmpleado:'121212',
              situacion:'SUB',
              fechaModificacion: '14/07/2023 23:11:12'
            },
            domicilio:{
              calle:'Ocholli',
              numExt:'Mz 14 lt 9',
              numInt:'',
              colonia:'Tlatelco',
              codigoPostal:'56353',
              ciudad:'Chimalhuacán',
              estado:'Estado de México',
              extranjero:0,
              fechaModificacion: '14/07/2023 23:11:12'
            },
            documentos:{
              ine:1,
              pasaporte:1,
              curp:1,
              ce:1,
              cassa:1
            }
          }
          */
        ],
    };
  },

  watch: {
    loader() {
      const l = this.loader;
      this[l] = !this[l];
      setTimeout(() => (this[l] = false), 2000);
      this.loader = null;
    },
  },

  created(){
    console.log('Authenticado = '+this.$store.state.auth.status.loggedIn);
    console.log('User',this.$store.state.auth.user);
    console.log('this.$store.state.auth',this.$store.state.auth);


  },
  computed: {
    currentUser() {
      console.log('currentUser');
      return this.$store.state.auth.user;
    },  
    loggedIn() {
      console.log('loggedIn');
      return this.$store.state.auth.status.loggedIn;
    },
    currentSession() {
      console.log('currentSession');
      var session = JSON.parse(this.$localStorage.get("data_user"));
      return session;
    }
  },

  mounted() {
    //console.log('--->'+this.$store.state.auth.status.loggedIn);
    if(!this.$store.state.auth.status.loggedIn){
      this.$store.dispatch("auth/logout");
      localStorage.removeItem("data_user");
      localStorage.removeItem("documentos");
      localStorage.removeItem("user_session");
      localStorage.removeItem("data_ine");
      localStorage.removeItem("data_pasaporte");
      localStorage.removeItem("data_curp");
      localStorage.removeItem("data_credencial");
      localStorage.removeItem("data_assa");
      this.$router.go();
    }else{
      var user = JSON.parse(this.$localStorage.get("data_user"));
      //console.log('usuario = '+user.usuario.usuario);
      if(user.usuario.usuario == 'sigema1@assa.mx' || user.usuario.usuario == 'sigema2@assa.mx'){
        this.userAdmin= true;
        this.nombre = user.usuario.usuario;
      }else{
        this.userAdmin= false;
        this.nombre = this.$store.state.auth.user.sobrecargo.nombre + ' '+ this.$store.state.auth.user.sobrecargo.primerApellido + ' '+ this.$store.state.auth.user.sobrecargo.segundoApellido;
      }
      document.title = "ASSA de México";
    }
  },

  methods: {
    logOut() {
      console.log('Authenticado = '+this.$store.state.auth.status.loggedIn);
      //this.$store.commit('logout');
      console.log('Authenticado = '+this.$store.state.auth.status.loggedIn);
      this.$store.dispatch("auth/logout");
      this.$router.go();
      
    },
    buscar(){
      if (this.$refs.form.validate()) {
        var usuario =  this.currentSession.usuario.usuario;
        var data = {
          criterio: this.criterio,
          valor: this.busquedaCadena,
          usuario: usuario
        };
        wsFlightAttendant.searchFlightAssistant(data).then((response) => {
            console.log(response);
            if(response.status == 200){
              switch(response.data.codigo){
                case "1":       
                  this.common.activate = true;
                  this.common.alert = 'info';
                  this.common.message = 'Se han encontrado resultados';
                  this.common.code = '0';
                  console.log('Tamanio = '+response.data.sobrecargos.lenghth);
                  console.log('-->'+response.data.sobrecargos);
                  this.desserts = response.data.sobrecargos;
                  //response.data.sobrecargos = null;
                  localStorage.removeItem("user");
                  localStorage.removeItem("data_user");
                  localStorage.setItem("user", JSON.stringify(response.data));  
                  localStorage.setItem("data_user", JSON.stringify(response.data));  
                  break;
                case "-1":
                  localStorage.removeItem("user");
                  localStorage.removeItem("data_user");
                  localStorage.setItem("user", JSON.stringify(response.data));  
                  localStorage.setItem("data_user", JSON.stringify(response.data));   
                  this.desserts = null; 
                  this.common.activate = true;
                  this.common.alert = 'warning';
                  this.common.message = 'No hay coincidencias con el criterio de busqueda';
                  this.common.code = '-1';  
                  break;
                case "-2":
                  localStorage.removeItem("user");
                  localStorage.removeItem("data_user");  
                  this.$router.push("/");
                  break;
                case "-3":
                  this.common.activate = true;
                  this.common.alert = 'warning';
                  this.common.message = 'No es posible realizar la operacion, contactar al administrador';
                  this.common.code = '-3';
                  break;
                case "100":    
                  this.common.activate = true;
                  this.common.alert = 'warning';
                  this.common.message = 'Captura datos de acuerdo al criterio de busqueda';
                  this.common.code = '100';  
                  break;
              }
            } else if(response.status == 403)  {
              localStorage.removeItem("user");
              this.$router.push("/");
            } else {
              this.common.activate = true;
              this.common.alert = 'warning';
              this.common.message = 'No es posible realizar la operacion, contactar al administrador';
              this.common.code = '0';  
            }
          },(error) => {
            if (error.response) {
              // La respuesta fue hecha y el servidor respondió con un código de estado, que esta fuera del rango de 2xx
              if(error.response.status == 403){
                localStorage.removeItem("user");
                this.$router.push("/");
              } else {
                this.common.activate = true;
                this.common.alert = 'warning';
                this.common.message = 'No es posible realizar la operacion, contactar al administrador';
                this.common.code = '-1';
              }
              console.log(error.response.data);
              console.log(error.response.status);
              console.log(error.response.headers);
            } else if (error.request) {
              // La petición fue hecha pero no se recibió respuesta
              // `error.request` es una instancia de XMLHttpRequest en el navegador y una instancia de
              // http.ClientRequest en node.js
              console.log('Error.Request');
              console.log(error.request);
              this.common.activate = true;
              this.common.alert = 'warning';
              this.common.message = 'No es posible realizar la operacion, contactar al administrador';
              this.common.code = '-2';
            } else {
              // Algo paso al preparar la petición que lanzo un Error
              console.log('Error');
              console.log(error.message);
              this.common.activate = true;
              this.common.alert = 'warning';
              this.common.message = 'No es posible realizar la operacion, contactar al administrador';
              this.common.code = '-3';
            }
          }
        );
      }
    },
    enviar(){
      var data = {
          usuario: {
            id: this.currentSession.usuario.id,
            usuario: this.currentSession.usuario.usuario,
          },
          sobrecargo: {
            id: this.currentSession.sobrecargo.id,
            nombre: this.currentSession.sobrecargo.nombre,
            primerApellido: this.currentSession.sobrecargo.primerApellido,
            segundoApellido: this.currentSession.sobrecargo.segundoApellido,
            curp: this.currentSession.sobrecargo.curp,
            correoElectronico: this.currentSession.sobrecargo.correoElectronico,
            telefono: this.currentSession.sobrecargo.telefono,
            telefonoMobil: this.currentSession.sobrecargo.telefonoMobil,
            avisoPriv: this.avisoPrivacidadFront == true ?1:0,
            finado: this.finadoFront == true ?1:0,
            pais:this.currentSession.sobrecargo.pais,
            extranjero:this.currentSession.sobrecargo.extranjero,
            calle:this.currentSession.sobrecargo.calle,
            numExt:this.currentSession.sobrecargo.numExt,
            numInt:this.currentSession.sobrecargo.numInt,
            colonia:this.currentSession.sobrecargo.colonia,
            codigoPostal:this.currentSession.sobrecargo.codigoPostal,
            ciudad:this.currentSession.sobrecargo.ciudad,
            estado:this.currentSession.sobrecargo.estado
          },
          empresa: {
            empresa: this.currentSession.empresa.empresa,
            numeroEmpleado: this.currentSession.empresa.numeroEmpleado,
            situacion: this.currentSession.empresa.situacion,
          },
          solicitud: {
            id: this.currentSession.solicitud.id,
            estatus: this.currentSession.solicitud.estatus,
            folio: null,
          }
        };
      wsFlightAttendant.sendRequest(data).then((response) => {
          console.log(response);
          if(response.status == 200){
            switch(response.data.codigo){
              case "1":
              localStorage.removeItem("user");
              localStorage.removeItem("data_user");
              localStorage.setItem("user", JSON.stringify(response.data));
              localStorage.setItem("data_user", JSON.stringify(response.data));
                this.common.activate = true;
                this.common.alert = 'info';
                this.common.message = 'Se ha enviado la solicitud y se ha generado un folio de operación.';
                this.common.code = '0';
                break;
              case "-1":
                this.common.activate = true;
                this.common.alert = 'warning';
                this.common.message = 'En estos momentos el sistema se encuentra en mantenimiento, pronto estaremos de regreso.';
                this.common.code = '0';
                localStorage.removeItem("ipAddress");
                localStorage.removeItem("estatus");
                localStorage.removeItem("folio");
                localStorage.removeItem("token");
                localStorage.removeItem("user");
                localStorage.removeItem("data_user");
                localStorage.removeItem("data_curp");
                localStorage.removeItem("data_pasaporte");
                localStorage.removeItem("data_credencial");
                localStorage.removeItem("data_ine");
                localStorage.removeItem("data_assa");
                this.$router.push("/");
                break;
            }
          } else {
            this.common.activate = true;
            this.common.alert = 'warning';
            this.common.message = 'En estos momentos el sistema se encuentra en mantenimiento, pronto estaremos de regreso.';
            this.common.code = '0';
            localStorage.removeItem("ipAddress");
            localStorage.removeItem("estatus");
            localStorage.removeItem("folio");
            localStorage.removeItem("token");
            localStorage.removeItem("user");
            localStorage.removeItem("data_user");
            localStorage.removeItem("data_curp");
            localStorage.removeItem("data_pasaporte");
            localStorage.removeItem("data_credencial");
            localStorage.removeItem("data_ine");
            localStorage.removeItem("data_assa");
            this.$router.push("/");
          }
        },(error) => {
          if (error.response) {
            // La respuesta fue hecha y el servidor respondió con un código de estado, que esta fuera del rango de 2xx
            if(error.response.status == 403){
              this.common.activate = true;
              this.common.alert = 'warning';
              this.common.message = 'En estos momentos el sistema se encuentra en mantenimiento, pronto estaremos de regreso.';
              this.common.code = '-1';
              localStorage.removeItem("ipAddress");
              localStorage.removeItem("estatus");
              localStorage.removeItem("folio");
              localStorage.removeItem("token");
              localStorage.removeItem("user");
              localStorage.removeItem("data_user");
              localStorage.removeItem("data_curp");
              localStorage.removeItem("data_pasaporte");
              localStorage.removeItem("data_credencial");
              localStorage.removeItem("data_ine");
              localStorage.removeItem("data_assa");
              this.$router.push("/");
            } else {
              this.common.activate = true;
              this.common.alert = 'warning';
              this.common.message = 'En estos momentos el sistema se encuentra en mantenimiento, pronto estaremos de regreso.';
              this.common.code = '-1';
            }
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            // La petición fue hecha pero no se recibió respuesta
            // `error.request` es una instancia de XMLHttpRequest en el navegador y una instancia de
            // http.ClientRequest en node.js
            console.log('Error.Request');
            console.log(error.request);
            this.common.activate = true;
            this.common.alert = 'warning';
            this.common.message = 'En estos momentos el sistema se encuentra en mantenimiento, pronto estaremos de regreso.';
            this.common.code = '-2';
          } else {
            // Algo paso al preparar la petición que lanzo un Error
            console.log('Error');
            console.log(error.message);
            this.common.activate = true;
            this.common.alert = 'warning';
            this.common.message = 'En estos momentos el sistema se encuentra en mantenimiento, pronto estaremos de regreso.';
            this.common.code = '-3';
          }
        }
      );
    
    },
    resetPassword() {
      if (this.$refs.form.validate() === true) {
        let userSession = JSON.parse(this.$localStorage.get("user"));
        var data = {
          usuario: userSession.usuario.usuario,
          contrasenia: this.set.password
        };
        /**/
        wsFlightAttendant.change(data).then((response) => {
            if(response.status == 200){
              switch(response.data.codigo){
                case "1":
                  this.common.activate = true;
                  this.common.alert = 'info';
                  this.common.message = 'Se ha modificado la contraseña exitosamente.';
                  this.common.code = '0';
                  this.panelContraseña = false;
                  break;
                case "-1":
                  this.common.activate = true;
                  this.common.alert = 'warning';
                  this.common.message = 'En estos momentos el sistema se encuentra en mantenimiento, pronto estaremos de regreso.';
                  this.common.code = '0';
                  this.panelContraseña = false;
                  break;
              }
            } else {
              this.common.activate = true;
              this.common.alert = 'warning';
              this.common.message = 'En estos momentos el sistema se encuentra en mantenimiento, pronto estaremos de regreso.';
              this.common.code = '0';
              localStorage.removeItem("ipAddress");
              localStorage.removeItem("estatus");
              localStorage.removeItem("folio");
              localStorage.removeItem("token");
              localStorage.removeItem("user");
              localStorage.removeItem("data_user");
              localStorage.removeItem("data_curp");
              localStorage.removeItem("data_pasaporte");
              localStorage.removeItem("data_credencial");
              localStorage.removeItem("data_ine");
              localStorage.removeItem("data_assa");
              this.$router.push("/");
            }
          },
          (error) => {
            if (error.response) {
              // La respuesta fue hecha y el servidor respondió con un código de estado, que esta fuera del rango de 2xx
              if(error.response.status == 403){
                this.common.activate = true;
                this.common.alert = 'warning';
                this.common.message = 'En estos momentos el sistema se encuentra en mantenimiento, pronto estaremos de regreso.';
                this.common.code = '-1';
                localStorage.removeItem("ipAddress");
                localStorage.removeItem("estatus");
                localStorage.removeItem("folio");
                localStorage.removeItem("token");
                localStorage.removeItem("user");
                localStorage.removeItem("data_user");
                localStorage.removeItem("data_curp");
                localStorage.removeItem("data_pasaporte");
                localStorage.removeItem("data_credencial");
                localStorage.removeItem("data_ine");
                localStorage.removeItem("data_assa");
                this.$router.push("/");
              } else {
                this.common.activate = true;
                this.common.alert = 'warning';
                this.common.message = 'En estos momentos el sistema se encuentra en mantenimiento, pronto estaremos de regreso.';
                this.common.code = '-1';
              }
              console.log(error.response.data);
              console.log(error.response.status);
              console.log(error.response.headers);
            } else if (error.request) {
              // La petición fue hecha pero no se recibió respuesta
              // `error.request` es una instancia de XMLHttpRequest en el navegador y una instancia de
              // http.ClientRequest en node.js
              console.log('Error.Request');
              console.log(error.request);
              this.common.activate = true;
              this.common.alert = 'warning';
              this.common.message = 'En estos momentos el sistema se encuentra en mantenimiento, pronto estaremos de regreso.';
              this.common.code = '-2';
            } else {
              // Algo paso al preparar la petición que lanzo un Error
              console.log('Error');
              console.log(error.message);
              this.common.activate = true;
              this.common.alert = 'warning';
              this.common.message = 'En estos momentos el sistema se encuentra en mantenimiento, pronto estaremos de regreso.';
              this.common.code = '-3';
            }
          }
          
        );
        
      }
    },
    
    saveRegister() {
      if (this.$refs.form.validate()) {
        console.log(
          "🚀 ~ file: FlightAttendant.vue:277 ~ saveRegister ~ this.$refs.form.validate():",
          this.$refs.form.validate()
        );
      }
    },

    sendRegister(MSG) {
      console.log(
        "🚀 ~ file: FlightAttendant.vue:284 ~ sendRegister ~ MSG:",
        MSG
      );
    },

    obtainSign(sender) {
      this.dataForm.firmaAutografa = sender.data.firmaAutografa;
    },

    obtainCurp(sender) {
      console.log(
        "🚀 ~ file: FlightAttendant.vue:310 ~ obtainCurp ~ sender:",
        sender.data
      );
      //const answer = JSON.stringify(sender.data);
      this.dataForm.documentoIdentificacion =
        sender.data.documentoIdentificacion[0].content;
      this.dataForm.documentoCredencial =
        sender.data.documentoCredencial[0].content;
    },

    saveDocument() {
      var data = {
        usuario: {
          id: 2,
          usuario: "isramemo@gmail.com",
        },
        documento: {
          documento: 2,
          documentoTipo: "CURP",
          persona: null,
          archivoNombre: null,
        },
        file: {},
      };
      console.log(
        "🚀 ~ file: FlightAttendant.vue:323 ~ saveDocument ~ data:",
        data
      );
    },
    logOut() {
      this.$store.dispatch("auth/logout");
      localStorage.removeItem("data_user");
      localStorage.removeItem("documentos");
      localStorage.removeItem("user_session");
      localStorage.removeItem("data_ine");
      localStorage.removeItem("data_pasaporte");
      localStorage.removeItem("data_curp");
      localStorage.removeItem("data_credencial");
      localStorage.removeItem("data_assa");
      this.$router.go();
    },
  },
};
</script>
<style></style>
