<template>
  <v-container>
    <v-row class="d-flex justify-center">
      <h1 class="display-2">Recuperar Acceso</h1>
    </v-row>

    <v-row class="d-flex justify-center">
      <v-col col xl="4" md="6" sm="12">
        <GlobalAlerts v-if="common.activate === true" 
                      style="text-align: justify;"
                      :cfgColorBg="this.common.alert == 'warning' ? 'amber darken-3' : 'light-green darken-2'"
                      :cfgIcon="this.common.alert == 'warning' ? 'mdi-alert-outline' : 'mdi-information-outline'"
                      cfgProminent="true"
                      :cfgMessage="common.message"/>

        <v-form ref="form" v-model="valid" lazy-validation>
          <v-text-field
            filled
            label="Correo electrónico"
            v-model="user.email"
            :rules="rules.email"
          />
          <div class="">
            <v-btn v-if="validate.codigo !== '1'"
              x-large  :disabled='isDisabled'
              color="indigo darken-4"
              dark
              @click="resetPassword()"
              class="float-right" 
            >
              <v-icon dark left> mdi-email </v-icon>
              Enviar
            </v-btn>
          </div>
        </v-form>
      </v-col>
    </v-row>
    <v-row> </v-row>
  </v-container>
</template>

<script>
import GlobalAlerts from "@/components/global/_Alerts.vue";
import wsAuthentication from "@/services/authentication";

export default {
  name: "Login",

  components: {
    GlobalAlerts,
  },

  data() {
    return {
      valid: true,
      showpass: false,
      errorStatus: "",
      validate: {},
      user: {
        email: "",
      },
      common: {
        activate: false,
        alert:'',
        message:'',
        code: ''
      },
      isDisabled:false,
      loading: "",
      rules: {
        email: [
          (v) => !!v || "El correo electrónico obligatorio",
          (v) => /.+@.+/.test(v) || "Con es un correo valido",
        ],
        password: [(v) => !!v || "La Contraseña es obligatoria"],
      },
    };
  },

  watch: {
    loader() {
      const l = this.loader;
      this[l] = !this[l];

      setTimeout(() => (this[l] = false), 2000);

      this.loader = null;
    },
  },

  computed: {
    message() {
      var message = this.$route.query.message;
      return message;
    },
  },

  mounted() {
    localStorage.removeItem("user_session");
    localStorage.removeItem("ipAddress");
    localStorage.removeItem("estatus");
    localStorage.removeItem("folio");
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    localStorage.removeItem("data_user");
    localStorage.removeItem("data_curp");
    localStorage.removeItem("data_pasaporte");
    localStorage.removeItem("data_credencial");
    localStorage.removeItem("data_ine");
    localStorage.removeItem("data_assa");
    document.title = "SIGEMA - RECUPERAR ACCESO";   
  },

  methods: {
    resetPassword() {
      if (this.$refs.form.validate() === true) {
        this.isDisabled = true;
        var data = {
          correoElectronico: this.user.email,
        };
        wsAuthentication.passwordRecovery(data).then((response) => {
            /*
            this.validate = response.data;
            if(this.validate.codigo === '3000'){
              this.validate.codigo = '1';
            }
            */
            if(response.status == 200){
              this.register = response.data;
              switch(response.data.codigo){
                case "1":
                  this.common.activate = true;
                  this.common.alert = 'info';
                  this.common.message = this.register.mensaje;
                  this.common.code = '1';
                  break;
                case "2":
                  this.common.activate = true;
                  this.common.alert = 'info';
                  this.common.message = this.register.mensaje;
                  this.common.code = '2';
                  break;
                case "3000":
                  this.common.activate = true;
                  this.common.alert = 'warning';
                  this.common.message =  this.register.mensaje;
                  this.common.code = '3000';
                  break;
                case "-1":
                  this.common.activate = true;
                  this.common.alert = 'warning';
                  this.common.message =  'En estos momentos el sistema se encuentra en mantenimiento, pronto estaremos de regreso.';
                  this.common.code = '-1';
                  break;
              }
            } else {
              this.common.activate = true;
              this.common.alert = 'warning';
              this.common.message = 'En estos momentos el sistema se encuentra en mantenimiento, pronto estaremos de regreso.';
              this.common.code = '0';
            }
          },
          (error) => {
            if (error.response) {
            // La respuesta fue hecha y el servidor respondió con un código de estado, que esta fuera del rango de 2xx
              if(error.response.status == 403){
                this.common.activate = true;
                this.common.alert = 'warning';
                this.common.message = 'En estos momentos el sistema se encuentra en mantenimiento, pronto estaremos de regreso.';
                this.common.code = '-1';
              } else {
                this.common.activate = true;
                this.common.alert = 'warning';
                this.common.message = 'En estos momentos el sistema se encuentra en mantenimiento, pronto estaremos de regreso.';
                this.common.code = '-1';
              }
              console.log(error.response.data);
              console.log(error.response.status);
              console.log(error.response.headers);
            } else if (error.request) {
              // La petición fue hecha pero no se recibió respuesta
              // `error.request` es una instancia de XMLHttpRequest en el navegador y una instancia de
              // http.ClientRequest en node.js
              console.log('Error.Request');
              console.log(error.request);
              this.common.activate = true;
              this.common.alert = 'warning';
              this.common.message = 'En estos momentos el sistema se encuentra en mantenimiento, pronto estaremos de regreso.';
              this.common.code = '-2';
            } else {
              // Algo paso al preparar la petición que lanzo un Error
              console.log('Error');
              console.log(error.message);
              this.common.activate = true;
              this.common.alert = 'warning';
              this.common.message = 'En estos momentos el sistema se encuentra en mantenimiento, pronto estaremos de regreso.';
              this.common.code = '-3';
            }
            this.isDisabled = false;
          }
        );
      }
    },
  },
};
</script>
