import Vue from "vue";
import VueRouter from "vue-router";
//import HomeView from '../views/HomeView.vue'

/*
 * Area Publica
 */
import Landing from "@/views/Landing.vue";
import Login from "@/views/auth/Login.vue";
import Register from "@/views/auth/Register.vue";
import RegisterValidate from "@/views/auth/RegisterValidate.vue";
import RecoveryValidate from "@/views/auth/ValidateRecovery.vue";
import PasswordSet from "@/views/auth/PasswordSet.vue";
import PasswordReset from "@/views/auth/PasswordReset.vue";

import FlightAttendant from "@/views/FlightAttendant.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Landing",
    component: Landing,
  },
  //Authentication
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  {
    path: "/register",
    name: "Register",
    component: Register,
  },
  {
    path: "/validate-register",
    name: "RegisterValidate",
    component: RegisterValidate,
  },
  {
    path: "/validate-recovery",
    name: "RecoveryValidate",
    component: RecoveryValidate,
  },
  {
    path: "/password/set",
    name: "PasswordSet",
    component: PasswordSet,
  },
  {
    path: "/password/reset",
    name: "PasswordReset",
    component: PasswordReset,
  },

  //Dashboard
  /* User > Profile */
  //{
  //  path: "/user/profile",
  //  name: "Profile",
  //  component: () => import("@/views/profile/Profile.vue"),
  //},
  /* Dashboard */
  {
    path: "/flight-attendant",
    name: "FlightAttendant",
    redirect: "/flight-attendant/personal-data",
    component: () => import(/* webpackChunkName: "FlightAttendant" */ "@/views/FlightAttendant.vue"),
    children: [
      {
        path: "/flight-attendant/personal-data",
        name: "FlightAttendantPersonalData",
        component: () =>
          import(
            /* webpackChunkName: "FlightAttendantPersonalData" */ "@/components/FlightAttendant/DatosPersonales"
          ),
      },
      {
        path: "/flight-attendant/employment-data",
        name: "FlightAttendantEmploymentData",
        component: () =>
          import(
            /* webpackChunkName: "FlightAttendantPersonalData" */ "@/components/FlightAttendant/DatosLaborales"
          ),
      },
      {
        path: "/flight-attendant/documents",
        name: "FlightAttendantDocuments",
        component: () =>
          import(
            /* webpackChunkName: "FlightAttendantDocuments" */ "@/components/FlightAttendant/Documentos"
          ),
      },
      {
        path: "/flight-attendant/sign",
        name: "FlightAttendantFirma",
        component: () =>
          import(
            /* webpackChunkName: "FlightAttendantCartaRenuncia" */ "@/components/FlightAttendant/Firma"
          ),
      },
      {
        path: "/flight-attendant/home",
        name: "FlightAttendantDatosResidencia",
        component: () =>
          import(
            /* webpackChunkName: "FlightAttendantCartaRenuncia" */ "@/components/FlightAttendant/DatosResidencia"
          ),
      },
    ]
  },{
    path: "/dashboard",
    name: "Dashboard",
    component: () =>
      import(/* webpackChunkName: "ThanksView" */ "@/views/Dashboard.vue")
  },{
    path: "/conciliation-arbitration",
    name: "ConciliationArbitration",
    component: () =>
      import(/* webpackChunkName: "ThanksView" */ "@/views/ConciliationArbitration.vue")
  },{
    path: "/flight-attendant-search",
    name: "FlightAttendantSearch",
    component: () =>
      import(/* webpackChunkName: "ThanksView" */ "@/views/admin/FlightAttendantSearch.vue")
  }
  /* Pages
  {
    path: "/cct/",
    name: "CctRegisterView",
    component: () =>
      import(/* webpackChunkName: "about" "@/views/cct/RegisterView.vue"),
  },
  */
];

const router = new VueRouter({
  mode: "history",
  base: process.env.VUE_APP_BASE_URL_CONTEXT,
  routes,
});

router.beforeEach((to, from, next) => {
  const publicPages = [
    "/",
    "/register",
    "/validate-register",
    "/validate-recovery",
    "/password/reset",
    "/login"
  ];

  const authRequired = !publicPages.includes(to.path);
  const loggedIn = localStorage.getItem("user");


  if (authRequired && !loggedIn) {
    next("/");
  } else {
    next();
  }
});

export default router;
