import http from "@/services/http-common";
import authHeader from "./auth-header";

class wsFlightAttendant {
  updateFlightAssistant(data) {
    return http.post("/request/flight-assistant/", data, {
      headers: authHeader(),
    });
  }

  updateFlightAssistantJfca(data) {
    return http.post("/request/flight-assistant/jfca/", data, {
      headers: authHeader(),
    });
  }

  updateFlightAssistantEmployment(data) {
    return http.post("/request/flight-assistant/employment/", data, {
      headers: authHeader(),
    });
  }

  updateFlightAssistantAddress(data) {
    return http.post("/request/flight-assistant/address/", data, {
      headers: authHeader(),
    });
  }

  updateDocumentSave(data) {
    return http.post("/request/flight-assistant/document/save/", data, {
      headers: authHeader(),
    });
  }

  getDocumentView(data) {
    return http.post("/request/flight-assistant/document/view/", data, {
      headers: authHeader(),
    });
  }

  change(data) {
    return http.post("/request/password/renew/", data, {
      headers: authHeader(),
    });
  }

  sendRequest(data) {
    return http.post("/request/flight-assistant/send/", data, {
      headers: authHeader(),
    });
  }

  searchFlightAssistant(data) {
    return http.post("/request/flight-assistant/search/", data, {
      headers: authHeader(),
    });
  }

  proceedings(data) {
    return http.post("/request/flight-assistant/proceedings/", data, {
      headers: authHeader(),
    });
  }

  /**
   * Delete File
   */
  deleteFile(id) {
    return http.delete(`/upload/files/${id}`, { headers: authHeader() });
  }
}

export default new wsFlightAttendant();
