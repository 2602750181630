<template>
  <div>
    <v-row class="">
      <v-col>
        <h1>Documentos</h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols md="12">
        <p class="text-justify">
          <strong>Nota:</strong> Los documentos a cargar en la plataforma
          únicamente son en formato <strong>PDF</strong>.
        </p>
      </v-col>
    </v-row>
    <v-row class="ma-4">
      <!-- INE -->
      <v-col cols md="4" sm="6">
        <GlobalAlerts
          v-if="
            messageStatus.documento === 'informationUpload' &&
            messageStatus.codigo === '1000' &&
            messageStatus.mensaje === 'success'
          "
          cfgColorBg="light-green darken-2"
          cfgIcon="mdi-check-all"
          class="text-center"
          cfgMessage="Identificación cargada con éxito."
        />
        <GlobalAlerts
          v-if="messageStatus.codigo === '100'"
          cfgColorBg="red darken-1"
          class="text-center"
          cfgIcon="mdi-skull-crossbones"
          cfgMessage="Ocurrio un error, intenta nuevamente."
        />
        <GlobalAlerts
          v-if="messageStatus === 'typeFileIne'"
          cfgColorBg="amber darken-3"
          cfgIcon="mdi-information"
          cfgMessage="Solo se permiten archivos PDF"
        />

        <div class="text-center" v-if="currentIne.archivoNombre && !updateIne">
          <!--
          <v-btn dark rounded color="green" target="_blank" @click="obtenerDocumento(currentIne)" class="ma-1">
            <v-icon left>mdi-file-pdf-box</v-icon>
            Descargar Archivo
          </v-btn>
          <br />
          -->
          <v-chip class="ma-1">{{ currentIne.archivoNombre }}</v-chip>
          <v-btn
            dark
            rounded
            color="primary"
            @click="updateIne = true"
            v-if="currentUser.solicitud.estatus !== 'ENVIADA'"
          >
            Actualizar
          </v-btn>
        </div>
        <div class="" v-if="!currentIne.archivoNombre || updateIne">
          <v-form
            ref="formIdentificacion"
            v-model="valid"
            lazy-validation
            class="mt-4"
          >
            <v-file-input
              :disabled="uploadStatus.identificacion"
              filled
              label="Identificación oficial INE"
              accept="application/pdf"
              :rules="rules.required"
              v-model="docIdentificacion"
              @change="typeFileIne"
            />
          </v-form>
          <v-btn
            v-if="!uploadStatus.identificacion"
            :disabled="noUploadIne"
            fab
            small
            dark
            color="blue darken-1"
            class="float-right"
            @click="saveIdentificacion()"
          >
            <v-icon>mdi-content-save</v-icon>
          </v-btn>
          <!--
          <v-btn v-if="uploadStatus.identificacion" dark rounded color="green" target="_blank" @click="obtenerDocumento(currentIne)">
            <v-icon left>mdi-file-pdf-box</v-icon>
              Ver documento
          </v-btn>
          -->
        </div>
      </v-col>
      <!-- CURP -->
      <v-col cols md="4" sm="6">
        <GlobalAlerts
          v-if="
            messageStatus.documento === 'curpUpload' &&
            messageStatus.codigo === '1000' &&
            messageStatus.mensaje === 'success'
          "
          cfgColorBg="light-green darken-2"
          cfgIcon="mdi-check-all"
          class="text-center"
          cfgMessage="Constancia de CURP cargada con éxito."
        />
        <GlobalAlerts
          v-if="messageStatus.codigo === '100'"
          cfgColorBg="red darken-1"
          cfgIcon="mdi-skull-crossbones"
          class="text-center"
          cfgMessage="Ocurrio un error, intenta nuevamente."
        />
        <GlobalAlerts
          v-if="messageStatus === 'typeFileCurp'"
          cfgColorBg="amber darken-3"
          cfgIcon="mdi-information"
          cfgMessage="Solo se permiten archivos PDF"
        />

        <div
          class="text-center"
          v-if="currentCurp.archivoNombre && !updateCurp"
        >
          <!--
          <v-btn dark rounded color="green" target="_blank" @click="obtenerDocumento(currentCurp)" class="ma-1">
            <v-icon left>mdi-file-pdf-box</v-icon>
            Descargar Archivo
          </v-btn>
          <br />
        -->
          <v-chip class="ma-1">{{ currentCurp.archivoNombre }}</v-chip>
          <v-btn
            dark
            rounded
            color="primary"
            @click="updateCurp = true"
            v-if="currentUser.solicitud.estatus !== 'ENVIADA'"
          >
            Actualizar
          </v-btn>
        </div>
        <div v-if="!currentCurp.archivoNombre || updateCurp" class="mt-4">
          <v-form ref="formCurp" v-model="valid" lazy-validation>
            <v-file-input
              :disabled="uploadStatus.curp"
              filled
              label="Constancia de CURP"
              accept="application/pdf"
              :rules="rules.required"
              v-model="docCurp"
              @change="typeFileCurp"
            />
          </v-form>
          <v-btn
            v-if="!uploadStatus.curp"
            :disabled="noUploadCurp"
            fab
            small
            dark
            color="blue darken-1"
            class="float-right"
            @click="saveCurp()"
          >
            <v-icon>mdi-content-save</v-icon>
          </v-btn>
          <!--
          <v-btn v-if="uploadStatus.curp" dark rounded color="green" target="_blank" @click="obtenerDocumento(currentCurp)">
            <v-icon left>mdi-file-pdf-box</v-icon>
            Ver documento
          </v-btn>
        --></div>
      </v-col>
      <!-- CREDENCIAL EMPRESA -->
      <v-col cols md="4" sm="6">
        <GlobalAlerts
          v-if="
            messageStatus.documento === 'credencialUpload' &&
            messageStatus.codigo === '1000' &&
            messageStatus.mensaje === 'success'
          "
          cfgColorBg="light-green darken-2"
          cfgIcon="mdi-check-all"
          class="text-center"
          cfgMessage="Credencial de Empresa cargada con éxito."
        />
        <GlobalAlerts
          v-if="messageStatus.codigo === '100'"
          cfgColorBg="red darken-1"
          cfgIcon="mdi-skull-crossbones"
          class="text-center"
          cfgMessage="Ocurrio un error, intenta nuevamente."
        />
        <GlobalAlerts
          v-if="messageStatus === 'typeFileCredencial'"
          cfgColorBg="amber darken-3"
          cfgIcon="mdi-information"
          cfgMessage="Solo se permiten archivos PDF"
        />

        <div
          class="text-center"
          v-if="currentCredencial.archivoNombre && !updateCredencial"
        >
          <!--
          <v-btn dark rounded color="green" target="_blank" @click="obtenerDocumento(currentCredencial)" class="ma-1">
            <v-icon left>mdi-file-pdf-box</v-icon>
            Descargar Archivo
          </v-btn>
          <br />
        -->
          <v-chip class="ma-1">{{ currentCredencial.archivoNombre }}</v-chip>
          <v-btn
            dark
            rounded
            color="primary"
            @click="updateCredencial = true"
            v-if="currentUser.solicitud.estatus !== 'ENVIADA'"
          >
            Actualizar
          </v-btn>
        </div>
        <div
          class="mt-4"
          v-if="!currentCredencial.archivoNombre || updateCredencial"
        >
          <v-form ref="formCredencial" v-model="valid" lazy-validation>
            <v-file-input
              :disabled="uploadStatus.credencial"
              filled
              label="Credencial de empresa"
              accept="application/pdf"
              :rules="rules.required"
              v-model="docCredencial"
              @change="typeFileCredencial"
            />
          </v-form>
          <v-btn
            v-if="!uploadStatus.credencial"
            :disabled="noUploadCredencial"
            fab
            small
            dark
            color="blue darken-1"
            class="float-right"
            @click="saveCredencial()"
          >
            <v-icon>mdi-content-save</v-icon>
          </v-btn>
          <!--
          <v-btn v-if="uploadStatus.credencial" dark rounded color="green" target="_blank" @click="obtenerDocumento(currentCredencial)" >
            <v-icon left>mdi-file-pdf-box</v-icon>
            Ver documento
          </v-btn>
        --></div>
      </v-col>
      <!-- PASAPORTE -->
      <v-col cols md="4" sm="6">
        <GlobalAlerts
          v-if="
            messageStatus.documento === 'pasaporteUpload' &&
            messageStatus.codigo === '1000' &&
            messageStatus.mensaje === 'success'
          "
          cfgColorBg="light-green darken-2"
          cfgIcon="mdi-check-all"
          class="text-center"
          cfgMessage="Pasaporte cargada con éxito."
        />
        <GlobalAlerts
          v-if="messageStatus.codigo === '100'"
          cfgColorBg="red darken-1"
          cfgIcon="mdi-skull-crossbones"
          class="text-center"
          cfgMessage="Ocurrio un error, intenta nuevamente."
        />
        <GlobalAlerts
          v-if="messageStatus === 'typeFilePasaporte'"
          cfgColorBg="amber darken-3"
          cfgIcon="mdi-information"
          cfgMessage="Solo se permiten archivos PDF"
        />

        <div
          class="text-center"
          v-if="currentPasaporte.archivoNombre && !updatePasaporte"
        >
          <!--
          <v-btn dark rounded color="green" target="_blank" @click="obtenerDocumento(currentPasaporte)" class="ma-1">
            <v-icon left>mdi-file-pdf-box</v-icon>
            Descargar Archivo
          </v-btn>
          <br />
        -->
          <v-chip class="ma-1">{{ currentPasaporte.archivoNombre }}</v-chip>
          <v-btn
            dark
            rounded
            color="primary"
            @click="updatePasaporte = true"
            v-if="currentUser.solicitud.estatus !== 'ENVIADA'"
          >
            Actualizar
          </v-btn>
        </div>
        <div class="" v-if="!currentPasaporte.archivoNombre || updatePasaporte">
          <v-form
            ref="formPasaporte"
            v-model="valid"
            lazy-validation
            class="mt-4"
          >
            <v-file-input
              :disabled="uploadStatus.pasaporte"
              filled
              label="Pasaporte"
              accept="application/pdf"
              :rules="rules.required"
              v-model="docPasaporte"
              @change="typeFilePasaporte"
            />
          </v-form>
          <v-btn
            v-if="!uploadStatus.pasaporte"
            :disabled="noUploadPasaporte"
            fab
            small
            dark
            color="blue darken-1"
            class="float-right"
            @click="savePasaporte()"
          >
            <v-icon>mdi-content-save</v-icon>
          </v-btn>
          <!--
          <v-btn v-if="uploadStatus.pasaporte" dark rounded color="green" target="_blank" @click="obtenerDocumento(currentPasaporte)">
            <v-icon left>mdi-file-pdf-box</v-icon>
            Ver documento
          </v-btn>
        --></div>
      </v-col>
      <!-- PASAPORTE -->
      <v-col cols md="4" sm="6">
        <GlobalAlerts
          v-if="
            messageStatus.documento === 'assaUpload' &&
            messageStatus.codigo === '1000' &&
            messageStatus.mensaje === 'success'
          "
          cfgColorBg="light-green darken-2"
          cfgIcon="mdi-check-all"
          class="text-center"
          cfgMessage="Credencial de ASSA cargada con éxito."
        />
        <GlobalAlerts
          v-if="messageStatus.codigo === '100'"
          cfgColorBg="red darken-1"
          cfgIcon="mdi-skull-crossbones"
          class="text-center"
          cfgMessage="Ocurrio un error, intenta nuevamente."
        />
        <GlobalAlerts
          v-if="messageStatus === 'typeFileAssa'"
          cfgColorBg="amber darken-3"
          cfgIcon="mdi-information"
          cfgMessage="Solo se permiten archivos PDF"
        />

        <div
          class="text-center"
          v-if="currentAssa.archivoNombre && !updateAssa"
        >
          <!--
          <v-btn dark rounded color="green" target="_blank" @click="obtenerDocumento(currentAssa)" class="ma-1">
            <v-icon left>mdi-file-pdf-box</v-icon>
            Descargar Archivo
          </v-btn>
          <br />
        -->
          <v-chip class="ma-1">{{ currentAssa.archivoNombre }}</v-chip>
          <v-btn
            dark
            rounded
            color="primary"
            @click="updateAssa = true"
            v-if="currentUser.solicitud.estatus !== 'ENVIADA'"
          >
            Actualizar
          </v-btn>
        </div>
        <div v-if="!currentAssa.archivoNombre || updateAssa" class="mt-4">
          <v-form ref="formAssa" v-model="valid" lazy-validation>
            <v-file-input
              :disabled="uploadStatus.assa"
              filled
              label="Credencial de ASSA"
              accept="application/pdf"
              :rules="rules.required"
              v-model="docAssa"
              @change="typeFileAssa"
            />
          </v-form>
          <v-btn
            v-if="!uploadStatus.assa"
            :disabled="noUploadAssa"
            fab
            small
            dark
            color="blue darken-1"
            class="float-right"
            @click="saveAssa()"
          >
            <v-icon>mdi-content-save</v-icon>
          </v-btn>
          <!--
          <v-btn v-if="uploadStatus.assa" dark rounded color="green" target="_blank" @click="obtenerDocumento(currentAssa)">
            <v-icon left>mdi-file-pdf-box</v-icon>
            Ver documento
          </v-btn>
        --></div>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols md="12" class="grey lighten-4 my-4 pa-4">
        <v-btn
          to="/flight-attendant/home"
          dark
          x-large
          color="blue darken-1"
          class="ma-2 float-right"
        >
          <v-icon>mdi-skip-previous</v-icon>
          Anterior
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import wsFlightAttendant from "@/services/flight-assistant";
import GlobalAlerts from "@/components/global/_Alerts.vue";

export default {
  name: "FlightAttendantDocumentosComponent",

  components: {
    GlobalAlerts,
  },

  data() {
    return {
      valid: true,
      completeRegister: false,
      messageStatus: {},

      downloadFile: {},

      updateIne: false,
      updateCurp: false,
      updateCredencial: false,
      updateAssa: false,
      updatePasaporte: false,

      docIdentificacion: null,
      docCredencial: null,
      docCurp: null,
      docAssa: null,
      docPasaporte: null,

      base64Identificacion: null,
      base64Credencial: null,
      base64Curp: null,
      base64Assa: null,
      base64Pasaporte: null,

      base64IdentificacionPrev: null,
      base64CredencialPrev: null,
      base64CurpPrev: null,
      base64AssaPrev: null,
      base64PasaportePrev: null,

      documentos: {
        documentoIdentificacion: [],
        documentoCredencial: [],
        documentoCurp: [],
        documentoAssa: [],
        documentoPasaporte: [],
      },

      dataForm: {
        documentoIdentificacion: [],
        documentoCredencial: [],
        documentoCurp: [],
        documentoAssa: [],
        documentoPasaporte: [],
      },

      fileTypeIne: undefined,
      noUploadIne: true,
      fileTypeCurp: undefined,
      noUploadCurp: true,
      fileTypeCredencial: undefined,
      noUploadCredencial: true,
      fileTypePasaporte: undefined,
      noUploadPasaporte: true,
      fileTypeAssa: undefined,
      noUploadAssa: true,

      uploadStatus: {
        identificacion: false,
        credencial: false,
        curp: false,
        assa: false,
        pasaporte: false,
      },

      rules: {
        required: [(value) => !!value || "El campo es obligatorio."],
        uploadFileSize: [
          (value) =>
            !value ||
            value.size < 2000000 ||
            "¡El documento debe ser menor a 2 MB!",
        ],
      },
    };
  },

  watch: {
    docIdentificacion: function (newVal, oldVal) {
      if (newVal) {
        this.createBase64Identificacion(newVal);
      } else {
        this.base64Identificacion = null;
      }
    },

    docCredencial: function (newVal, oldVal) {
      if (newVal) {
        this.createBase64Credencial(newVal);
      } else {
        this.base64Credencial = null;
      }
    },

    docCurp: function (newVal, oldVal) {
      if (newVal) {
        this.createBase64Curp(newVal);
      } else {
        this.base64Curp = null;
      }
    },

    docAssa: function (newVal, oldVal) {
      if (newVal) {
        this.createBase64Assa(newVal);
      } else {
        this.base64Assa = null;
      }
    },

    docPasaporte: function (newVal, oldVal) {
      if (newVal) {
        this.createBase64Pasaporte(newVal);
      } else {
        this.base64Pasaporte = null;
      }
    },
  },

  mounted() {},

  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },

    currentSession() {
      var session = JSON.parse(this.$localStorage.get("data_user"));
      return session;
    },

    currentIne() {
      var session = JSON.parse(this.$localStorage.get("data_ine"));
      return session;
    },

    currentCredencial() {
      var session = JSON.parse(this.$localStorage.get("data_credencial"));
      return session;
    },

    currentCurp() {
      var session = JSON.parse(this.$localStorage.get("data_curp"));
      return session;
    },

    currentAssa() {
      var session = JSON.parse(this.$localStorage.get("data_assa"));
      return session;
    },

    currentPasaporte() {
      var session = JSON.parse(this.$localStorage.get("data_pasaporte"));
      return session;
    },

    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
  },

  methods: {
    saveIdentificacion() {
      if (this.$refs.formIdentificacion.validate()) {
        var data = {
          usuario: {
            id: this.currentSession.usuario.id,
            usuario: this.currentSession.usuario.usuario,
          },
          documento: {
            documento: 1,
            documentoTipo: "INE",
            persona: this.currentSession.sobrecargo.id,
            archivoNombre: this.currentIne.archivoNombre
              ? this.currentIne.archivoNombre
              : null,
          },
          file: this.base64Identificacion,
        };
        wsFlightAttendant.updateDocumentSave(data).then(
          (response) => {
            this.registerFlightAssistant = response.data;
            this.$localStorage.set(
              "user",
              JSON.stringify(this.registerFlightAssistant)
            );
            this.currentIne.archivoNombre =
              this.registerFlightAssistant.documento.archivoNombre;
            localStorage.removeItem("data_ine");
            localStorage.setItem(
              "data_ine",
              JSON.stringify(this.registerFlightAssistant.documento)
            );
            this.messageStatus = {
              documento: "informationUpload",
              codigo: this.registerFlightAssistant.codigo,
              mensaje: this.registerFlightAssistant.mensaje,
            };
            this.uploadStatus.identificacion = true;
          },
          (error) => {
            this.registerFlightAssistant =
              (error.response && error.response.data) ||
              error.message ||
              error.toString();
          }
        );
      }
    },
    saveCurp() {
      if (this.$refs.formCurp.validate()) {
        var data = {
          usuario: {
            id: this.currentSession.usuario.id,
            usuario: this.currentSession.usuario.usuario,
          },
          documento: {
            documento: 3,
            documentoTipo: "CURP",
            persona: this.currentSession.sobrecargo.id,
            archivoNombre: this.currentCurp.archivoNombre
              ? this.currentCurp.archivoNombre
              : null,
          },
          file: this.base64Curp,
        };
        wsFlightAttendant.updateDocumentSave(data).then(
          (response) => {
            this.registerFlightAssistant = response.data;
            console.log(
              "🚀 ~ file: Documentos.vue:240 ~ saveCurp ~ this.registerFlightAssistant:",
              this.registerFlightAssistant
            );
            this.$localStorage.set(
              "user",
              JSON.stringify(this.registerFlightAssistant)
            );
            this.currentCurp.archivoNombre =
              this.registerFlightAssistant.documento.archivoNombre;
            localStorage.removeItem("data_curp");
            localStorage.setItem(
              "data_curp",
              JSON.stringify(this.registerFlightAssistant.documento)
            );
            this.messageStatus = {
              documento: "curpUpload",
              codigo: this.registerFlightAssistant.codigo,
              mensaje: this.registerFlightAssistant.mensaje,
            };
            this.uploadStatus.curp = true;
          },
          (error) => {
            this.registerFlightAssistant =
              (error.response && error.response.data) ||
              error.message ||
              error.toString();
          }
        );
      }
    },
    savePasaporte() {
      if (this.$refs.formPasaporte.validate()) {
        var data = {
          usuario: {
            id: this.currentSession.usuario.id,
            usuario: this.currentSession.usuario.usuario,
          },
          documento: {
            documento: 2,
            documentoTipo: "PASAPORTE",
            persona: this.currentSession.sobrecargo.id,
            archivoNombre: this.currentPasaporte.archivoNombre
              ? this.currentPasaporte.archivoNombre
              : null,
          },
          file: this.base64Pasaporte,
        };
        wsFlightAttendant.updateDocumentSave(data).then(
          (response) => {
            this.registerFlightAssistant = response.data;
            this.$localStorage.set(
              "user",
              JSON.stringify(this.registerFlightAssistant)
            );
            this.currentPasaporte.archivoNombre =
              this.registerFlightAssistant.documento.archivoNombre;
            localStorage.removeItem("data_pasaporte");
            localStorage.setItem(
              "data_pasaporte",
              JSON.stringify(this.registerFlightAssistant.documento)
            );
            this.messageStatus = {
              documento: "pasaporteUpload",
              codigo: this.registerFlightAssistant.codigo,
              mensaje: this.registerFlightAssistant.mensaje,
            };
            this.uploadStatus.pasaporte = true;
          },
          (error) => {
            this.registerFlightAssistant =
              (error.response && error.response.data) ||
              error.message ||
              error.toString();
          }
        );
      }
    },
    saveCredencial() {
      if (this.$refs.formCredencial.validate()) {
        var data = {
          usuario: {
            id: this.currentSession.usuario.id,
            usuario: this.currentSession.usuario.usuario,
          },
          documento: {
            documento: 4,
            documentoTipo: "CREDENCIAL DE EMPLEADO",
            persona: this.currentSession.sobrecargo.id,
            archivoNombre: this.currentCredencial.archivoNombre
              ? this.currentCredencial.archivoNombre
              : null,
          },
          file: this.base64Credencial,
        };
        wsFlightAttendant.updateDocumentSave(data).then(
          (response) => {
            this.registerFlightAssistant = response.data;
            console.log(
              "🚀 ~ file: Documentos.vue:283 ~ saveCredencial ~ this.registerFlightAssistant:",
              this.registerFlightAssistant
            );
            this.$localStorage.set(
              "user",
              JSON.stringify(this.registerFlightAssistant)
            );
            this.currentCredencial.archivoNombre =
              this.registerFlightAssistant.documento.archivoNombre;
            localStorage.removeItem("data_credencial");
            localStorage.setItem(
              "data_credencial",
              JSON.stringify(this.registerFlightAssistant.documento)
            );
            this.messageStatus = {
              documento: "credencialUpload",
              codigo: this.registerFlightAssistant.codigo,
              mensaje: this.registerFlightAssistant.mensaje,
            };
            this.uploadStatus.credencial = true;
          },
          (error) => {
            this.registerFlightAssistant =
              (error.response && error.response.data) ||
              error.message ||
              error.toString();
          }
        );
      }
    },

    saveAssa() {
      if (this.$refs.formAssa.validate()) {
        var data = {
          usuario: {
            id: this.currentSession.usuario.id,
            usuario: this.currentSession.usuario.usuario,
          },
          documento: {
            documento: 5,
            documentoTipo: "CREDENCIAL DE ASSA",
            persona: this.currentSession.sobrecargo.id,
            archivoNombre: this.currentAssa.archivoNombre
              ? this.currentAssa.archivoNombre
              : null,
          },
          file: this.base64Assa,
        };
        wsFlightAttendant.updateDocumentSave(data).then(
          (response) => {
            this.registerFlightAssistant = response.data;
            this.$localStorage.set(
              "user",
              JSON.stringify(this.registerFlightAssistant)
            );
            this.currentAssa.archivoNombre =
              this.registerFlightAssistant.documento.archivoNombre;
            localStorage.removeItem("data_assa");
            localStorage.setItem(
              "data_assa",
              JSON.stringify(this.registerFlightAssistant.documento)
            );
            this.messageStatus = {
              documento: "assaUpload",
              codigo: this.registerFlightAssistant.codigo,
              mensaje: this.registerFlightAssistant.mensaje,
            };
            this.uploadStatus.assa = true;
          },
          (error) => {
            this.registerFlightAssistant =
              (error.response && error.response.data) ||
              error.message ||
              error.toString();
          }
        );
      }
    },

    obtenerArchivo(doc) {
      console.log("🚀 ~ file: Documentos.vue:468 ~ obtenerArchivo ~ doc:", doc);
    },

    obtenerDocumento(documento) {
      var data = {
        usuario: {
          id: this.currentSession.usuario.id,
          usuario: this.currentSession.usuario.usuario,
        },
        documento,
      };
      console.log(
        "🚀 ~ file: Documentos.vue:496 ~ obtenerDocumento ~ data:",
        data
      );
      wsFlightAttendant.getDocumentView(data).then(
        (response) => {
          this.downloadFile = response.data;
          console.log(
            "🚀 ~ file: Documentos.vue:501 ~ obtenerDocumento ~ this.downloadFile:",
            this.downloadFile
          );
          this.$localStorage.set("user", JSON.stringify(this.downloadFile));
          this.messageStatus = {
            codigo: this.downloadFile.codigo,
            mensaje: this.downloadFile.mensaje,
          };
          this.openPdf(this.downloadFile.file);
        },
        (error) => {
          this.downloadFile =
            (error.response && error.response.data) ||
            error.message ||
            error.toString();
        }
      );
    },

    createBase64Identificacion: function (FileObject) {
      const reader = new FileReader();
      reader.onload = (event) => {
        this.base64Identificacion = this.cleanHeaderData(event.target.result);
        this.base64IdentificacionPrev = event.target.result;
      };
      reader.readAsDataURL(FileObject);
    },
    createBase64Credencial: function (FileObject) {
      const reader = new FileReader();
      reader.onload = (event) => {
        this.base64Credencial = this.cleanHeaderData(event.target.result);
        this.base64CredencialPrev = event.target.result;
      };
      reader.readAsDataURL(FileObject);
    },
    createBase64Curp: function (FileObject) {
      const reader = new FileReader();
      reader.onload = (event) => {
        this.base64Curp = this.cleanHeaderData(event.target.result);
        this.base64CurpPrev = event.target.result;
      };
      reader.readAsDataURL(FileObject);
    },
    createBase64Assa: function (FileObject) {
      const reader = new FileReader();
      reader.onload = (event) => {
        this.base64Assa = this.cleanHeaderData(event.target.result);
        this.base64AssaPrev = event.target.result;
      };
      reader.readAsDataURL(FileObject);
    },
    createBase64Pasaporte: function (FileObject) {
      const reader = new FileReader();
      reader.onload = (event) => {
        this.base64Pasaporte = this.cleanHeaderData(event.target.result);
        this.base64PasaportePrev = event.target.result;
      };
      reader.readAsDataURL(FileObject);
    },

    openPdf(data) {
      let byteCharacters = atob(data);
      let byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      let byteArray = new Uint8Array(byteNumbers);
      let file = new Blob([byteArray], { type: "application/pdf;base64" });
      let fileURL = URL.createObjectURL(file);
      window.open(fileURL);
    },

    cleanHeaderData(key) {
      var remplace = key.replace("data:application/pdf;base64,", "");
      return remplace;
    },

    typeFileIne(file) {
      this.fileTypeIne = file;
      if (this.fileTypeIne.type != "application/pdf") {
        this.messageStatus = "typeFileIne";
        this.noUploadIne = true;
      } else if (this.fileTypeIne.type === "application/pdf") {
        this.noUploadIne = false;
        this.messageStatus = {};
      } else {
        this.noUploadIne = true;
        this.messageStatus = {};
        this.fileTypeIne = undefined;
      }
      //this.showFile = URL.createObjectURL(this.fileType);
    },

    typeFileCurp(file) {
      this.fileTypeCurp = file;
      if (this.fileTypeCurp.type != "application/pdf") {
        this.messageStatus = "typeFileCurp";
        this.noUploadCurp = true;
      } else if (this.fileTypeCurp.type === "application/pdf") {
        this.noUploadCurp = false;
        this.messageStatus = {};
      } else {
        this.noUploadCurp = true;
        this.messageStatus = {};
        this.fileTypeCurp = undefined;
      }
    },

    typeFileCredencial(file) {
      this.fileTypeCredencial = file;
      if (this.fileTypeCredencial.type != "application/pdf") {
        this.messageStatus = "typeFileCredencial";
        this.noUploadCredencial = true;
      } else if (this.fileTypeCredencial.type === "application/pdf") {
        this.noUploadCredencial = false;
        this.messageStatus = {};
      } else {
        this.noUploadCredencial = true;
        this.messageStatus = {};
        this.fileTypeCredencial = undefined;
      }
    },

    typeFilePasaporte(file) {
      this.fileTypePasaporte = file;
      if (this.fileTypePasaporte.type != "application/pdf") {
        this.messageStatus = "typeFilePasaporte";
        this.noUploadPasaporte = true;
      } else if (this.fileTypePasaporte.type === "application/pdf") {
        this.noUploadPasaporte = false;
        this.messageStatus = {};
      } else {
        this.noUploadPasaporte = true;
        this.messageStatus = {};
        this.fileTypePasaporte = undefined;
      }
    },

    typeFileAssa(file) {
      this.fileTypeAssa = file;
      if (this.fileTypeAssa.type != "application/pdf") {
        this.messageStatus = "typeFileAssa";
        this.noUploadAssa = true;
      } else if (this.fileTypeAssa.type === "application/pdf") {
        this.noUploadAssa = false;
        this.messageStatus = {};
      } else {
        this.noUploadAssa = true;
        this.messageStatus = {};
        this.fileTypeAssa = undefined;
      }
    },
  },
};
</script>
