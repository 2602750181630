<template>
  <v-container>
    <v-row>
        <v-col cols="12" sm="10">
            <h2 class="display-1"><v-icon class="display-1">mdi-home</v-icon>Cambio de contraseña</h2>
        </v-col>
        <v-col cols="12" sm="2" style="text-align: right;">
            <v-btn x-large block color="red darken-4" class="white--text" @click="logOut()">
                <v-icon>mdi-logout</v-icon><strong>SALIR</strong>
            </v-btn>
        </v-col>
        <v-col cols="12">
            <hr class="red"/>
            <v-breadcrumbs :items="breadcrums" divider="/"></v-breadcrumbs>
        </v-col>
    </v-row>
    <v-row>
        <v-col cols="12">
            <p class="text-lg-right"> <strong>{{ nombre }}</strong> <v-icon x-large>mdi-account</v-icon></p>
        </v-col>
    </v-row>
    <v-row>
      <v-col cols md="12">
        <GlobalAlerts v-if="common.activate === true" 
                      style="text-align: justify;"
                      :cfgColorBg="this.common.alert == 'warning' ? 'amber darken-3' : 'light-green darken-2'"
                      :cfgIcon="this.common.alert == 'warning' ? 'mdi-alert-outline' : 'mdi-information-outline'"
                      cfgProminent="true"
                      :cfgMessage="common.message"/>
      </v-col>
    </v-row>
    <v-row class="d-flex justify-center">
      <v-col col xl="4" md="6" sm="12">
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-text-field
            filled
            label="Contraseña"
            v-model="set.password"
            :rules="rules.password"
            :append-icon="showpass ? 'mdi-eye' : 'mdi-eye-off'"
            :type="showpass ? 'text' : 'password'"
            hint="Coloca al menos 8 caracteres"
            counter
            @click:append="showpass = !showpass"
          />
          <v-text-field
            filled
            label="Repetir contraseña"
            :rules="passwordConfirm"
            :append-icon="showpass ? 'mdi-eye' : 'mdi-eye-off'"
            :type="showpass ? 'text' : 'password'"
            hint="Coloca al menos 8 caracteres"
            counter
            @click:append="showpass = !showpass"
          />
          <div class="">
            <v-btn x-large color="indigo darken-4" dark @click="resetPassword()" class="float-right">
              <v-icon dark left> mdi-content-save-check-outline </v-icon>guardar
            </v-btn>
          </div>
          <br/><br/><br/><br/>
        </v-form>
      </v-col>
    </v-row>
    <v-row> </v-row>
  </v-container>
</template>

<script>
import GlobalAlerts from "@/components/global/_Alerts.vue";
import wsFlightAttendant from "@/services/flight-assistant";

export default {
  name: "PasswordSet",

  components: {
    GlobalAlerts,
  },

  data() {
    return {
      nombre: ' ',
      breadcrums: [
        {
          text: 'Dashboard',
          disabled: false,
          href: '/dashboard',
        },
        {
          text: 'Cambio de contraseña',
          disabled: true,
          href: '/password/set',
        }
      ],
      set: {
        password: "",
      },
      loader: null,
      loading: false,
      valid: true,
      showpass: false,
      errorStatus: "",
      setPassword: {},
      rules: {
        password: [(v) => !!v || "La Contraseña es obligatoria"],
      },
      common: {
        activate: false,
        alert:'',
        message:'',
        code: ''
      }
    };
  },

  computed: {
    message() {
      var message = this.$route.query.message;
      return message;
    },
    passwordConfirm() {
      return [(v) => v === this.set.password || "Las contraseñas no coinciden"];
    },
  },

  mounted() {
      //console.log('--->'+this.$store.state.auth.status.loggedIn);
      if(!this.$store.state.auth.status.loggedIn){
        this.$store.dispatch("auth/logout");
        localStorage.removeItem("data_user");
        localStorage.removeItem("documentos");
        localStorage.removeItem("user_session");
        localStorage.removeItem("data_ine");
        localStorage.removeItem("data_pasaporte");
        localStorage.removeItem("data_curp");
        localStorage.removeItem("data_credencial");
        localStorage.removeItem("data_assa");
        this.$router.go();
      }else{
        var user = JSON.parse(this.$localStorage.get("data_user"));
        if(user.usuario.usuario == 'sigema1@assa.mx' || user.usuario.usuario == 'sigema2@assa.mx'){
          this.userAdmin= true;
          this.nombre = user.usuario.usuario;
        }else{
          this.nombre = this.$store.state.auth.user.sobrecargo.nombre + ' '+ this.$store.state.auth.user.sobrecargo.primerApellido + ' '+ this.$store.state.auth.user.sobrecargo.segundoApellido;
          this.userAdmin= false;
        }
        document.title = "ASSA de México";
      }
    },

  methods: {
    logOut() {
      this.$store.dispatch("auth/logout");
      localStorage.removeItem("data_user");
      localStorage.removeItem("documentos");
      localStorage.removeItem("user_session");
      localStorage.removeItem("data_ine");
      localStorage.removeItem("data_pasaporte");
      localStorage.removeItem("data_curp");
      localStorage.removeItem("data_credencial");
      localStorage.removeItem("data_assa");
      this.$router.go();
    },
    resetPassword() {
      if (this.$refs.form.validate() === true) {
        let userSession = JSON.parse(this.$localStorage.get("user"));
        var data = {
          usuario: userSession.usuario.usuario,
          contrasenia: this.set.password
        };
        wsFlightAttendant.change(data).then((response) => {
            if(response.status == 200){
              switch(response.data.codigo){
                case "1":
                  this.common.activate = true;
                  this.common.alert = 'info';
                  this.common.message = 'Se ha modificado la contraseña exitosamente.';
                  this.common.code = '0';
                  this.panelContraseña = false;
                  break;
                case "-1":
                  this.common.activate = true;
                  this.common.alert = 'warning';
                  this.common.message = 'En estos momentos el sistema se encuentra en mantenimiento, pronto estaremos de regreso.';
                  this.common.code = '0';
                  this.panelContraseña = false;
                  break;
              }
            } else {
              this.common.activate = true;
              this.common.alert = 'warning';
              this.common.message = 'En estos momentos el sistema se encuentra en mantenimiento, pronto estaremos de regreso.';
              this.common.code = '0';
              localStorage.removeItem("ipAddress");
              localStorage.removeItem("estatus");
              localStorage.removeItem("folio");
              localStorage.removeItem("token");
              localStorage.removeItem("user");
              localStorage.removeItem("data_user");
              localStorage.removeItem("data_curp");
              localStorage.removeItem("data_pasaporte");
              localStorage.removeItem("data_credencial");
              localStorage.removeItem("data_ine");
              localStorage.removeItem("data_assa");
              this.$router.push("/");
            }
          },
          (error) => {
            if (error.response) {
              // La respuesta fue hecha y el servidor respondió con un código de estado, que esta fuera del rango de 2xx
              if(error.response.status == 403){
                this.common.activate = true;
                this.common.alert = 'warning';
                this.common.message = 'En estos momentos el sistema se encuentra en mantenimiento, pronto estaremos de regreso.';
                this.common.code = '-1';
                localStorage.removeItem("ipAddress");
                localStorage.removeItem("estatus");
                localStorage.removeItem("folio");
                localStorage.removeItem("token");
                localStorage.removeItem("user");
                localStorage.removeItem("data_user");
                localStorage.removeItem("data_curp");
                localStorage.removeItem("data_pasaporte");
                localStorage.removeItem("data_credencial");
                localStorage.removeItem("data_ine");
                localStorage.removeItem("data_assa");
                this.$router.push("/");
              } else {
                this.common.activate = true;
                this.common.alert = 'warning';
                this.common.message = 'En estos momentos el sistema se encuentra en mantenimiento, pronto estaremos de regreso.';
                this.common.code = '-1';
              }
              console.log(error.response.data);
              console.log(error.response.status);
              console.log(error.response.headers);
            } else if (error.request) {
              // La petición fue hecha pero no se recibió respuesta
              // `error.request` es una instancia de XMLHttpRequest en el navegador y una instancia de
              // http.ClientRequest en node.js
              console.log('Error.Request');
              console.log(error.request);
              this.common.activate = true;
              this.common.alert = 'warning';
              this.common.message = 'En estos momentos el sistema se encuentra en mantenimiento, pronto estaremos de regreso.';
              this.common.code = '-2';
            } else {
              // Algo paso al preparar la petición que lanzo un Error
              console.log('Error');
              console.log(error.message);
              this.common.activate = true;
              this.common.alert = 'warning';
              this.common.message = 'En estos momentos el sistema se encuentra en mantenimiento, pronto estaremos de regreso.';
              this.common.code = '-3';
            }
          }
          
        );
      }
    },
  },
};
</script>
