<template>
  <div>
    <v-row class="">
      <v-col>
        <h1>Generales</h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols md="12">
        <GlobalAlerts v-if="common.activate === true" 
                      style="text-align: justify;"
                      :cfgColorBg="this.common.alert == 'warning' ? 'amber darken-3' : 'light-green darken-2'"
                      :cfgIcon="this.common.alert == 'warning' ? 'mdi-alert-outline' : 'mdi-information-outline'"
                      cfgProminent="true"
                      :cfgMessage="common.message"/>
      </v-col>
    </v-row>
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-row>
        <v-col cols md="6">
          <v-text-field filled :rules="rules.required" label="Nombre(s):" v-model="currentSession.sobrecargo.nombre"/>
        </v-col>
        <v-col cols md="6">
          <v-text-field filled :rules="rules.required" label="Primer apellido:" v-model="currentSession.sobrecargo.primerApellido"/>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols md="6">
          <v-text-field filled :rules="rules.required" label="Segundo apellido:" v-model="currentSession.sobrecargo.segundoApellido"/>
        </v-col>
        <v-col cols md="6">
          <v-text-field readonly filled :rules="rules.required" label="Correo electrónico (No institucional):" v-model="currentSession.sobrecargo.correoElectronico" class=""/>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols md="6">
          <v-text-field filled label="Teléfono de contacto:" :rules="rules.required" v-model="currentSession.sobrecargo.telefono"/>
        </v-col>
        <v-col cols md="6">
          <v-text-field filled label="Teléfono de móvil:" :rules="rules.required" v-model="currentSession.sobrecargo.telefonoMobil"/>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols md="2" class="d-flex justify-start align-center">
          <v-checkbox class="strong" v-model="finadoFront" label=""></v-checkbox>
          <v-btn small text color="primary">
            Finado
          </v-btn>
        </v-col>
        <v-col cols md="10" class="d-flex justify-start align-center">
          <p class="text-justify yellow"><strong>¡IMPORTANTE!</strong><br/>Esta sección unicamente se responde habilitando la casilla en caso de que el sobrecargo registrado este fallecido.</p>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols md="12">
          <p class="text-justify"><strong>¡IMPORTANTE!</strong><br/>Favor de ingresar tu información correctamente ya que ésta será validada por la Autoridad Federal y el RENAPO.</p>
          <v-text-field filled :rules="rules.required" label="CURP:" v-model="currentSession.sobrecargo.curp"/>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols md="12" class="d-flex justify-start align-center">
          <v-checkbox class="strong" :rules="rules.required" v-model="avisoPrivacidadFront" label=""></v-checkbox>
          <v-btn small text color="primary" @click="dialog = true">
            Aviso de Privacidad
          </v-btn>
        </v-col>
      </v-row>
      <v-row v-if="currentUser.solicitud.estatus !== 'ENVIADA'">
        <v-col cols md="12">
          <p class="text-justify"><strong>Nota:</strong> Si realizas alguna actualización en la sección previa, es necesario que presiones el botón verde con la leyenda de Guardar. En caso de no realizar el procedimiento mencionado los datos se perderán.</p>
        </v-col>
      </v-row>
      <v-row v-if="currentUser.solicitud.estatus !== 'ENVIADA'">
        <v-col cols md="4"></v-col>
        <v-col cols md="4">
          <v-btn @click="saveRegister()" dark x-large color="green darken-1" block>
          <v-icon>mdi-content-save</v-icon>
          Guardar
        </v-btn>
        <br/>
        </v-col>
        <v-col cols md="4"></v-col>
      </v-row>
      <v-row>
        <v-col cols md="12">
          <GlobalAlerts v-if="common.activate === true" 
                        style="text-align: justify;"
                        :cfgColorBg="this.common.alert == 'warning' ? 'amber darken-3' : 'light-green darken-2'"
                        :cfgIcon="this.common.alert == 'warning' ? 'mdi-alert-outline' : 'mdi-information-outline'"
                        cfgProminent="true"
                        :cfgMessage="common.message"/>
        </v-col>
      </v-row>
    </v-form>
    <v-row>
      <v-col cols md="12" class="grey lighten-4 my-4 pa-4">
        <v-col cols md="12">
          <p class="text-justify"><strong>Nota:</strong> Los botones de la siguiente sección es para poder navegar entre las diversas pantallas de la aplicaciòn.</p>
        </v-col>
        <v-btn to="/flight-attendant/employment-data" dark x-large color="blue darken-1" class="ma-2 float-right">
          <v-icon>mdi-skip-next</v-icon>
          Siguiente
        </v-btn>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-dialog v-model="dialog" persistent max-width="50%">
        <v-card>
          <v-card-title class="text-h5"> Aviso de Privacidad </v-card-title>
          <v-card-text>
            <p>
              <strong>ASSA de México</strong>, de acuerdo al Artículo 17
              Fracción X y demás relativos y aplicables del Estatuto de la
              Asociación Sindical de Sobrecargos de Aviación de México, declara
              las condiciones de resguardo, reserva, custodia, protección de la
              seguridad y confidencialidad de la información, datos personales y
              de todo tipo de documentos que Usted compartirá en la plataforma,
              declaración que realiza a través de su Secretaria General, lo
              anterior al ser quien ejerce su representación legal, esto en
              concordancia con lo establecido en la Ley General de Protección de
              Datos Personales en Posesión de Sujetos Obligados.
            </p>
            <p>
              El presente compromiso nos responsabiliza respecto de la
              información que nos sea proporcionada, ya sea de forma oral,
              escrita, impresa, sonora, visual, electrónica, informática u
              holográfica, contenida en cualquier tipo de documento.
            </p>
            <p>
              La información que nos sea proporcionada será considerada, según
              el caso, como reservada, privilegiada y confidencial, en los
              términos del Estatuto de la ASSA de México y las leyes aplicables.
            </p>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="red darken-3" text @click="privacy('no')">
              No acepto
            </v-btn>
            <v-btn color="green darken-3" text @click="privacy('si')">
              Acepto
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>
<script>
import wsFlightAttendant from "@/services/flight-assistant";
import GlobalAlerts from "@/components/global/_Alerts.vue";
import jsonEstados from "@/services/catalogos/estados.json";
import jsonEmpresa from "@/services/catalogos/empresa.json";
import jsonSituacion from "@/services/catalogos/situacion.json";

export default {
  name: "FlightAttendantDatosPersonalesComponent",

  components: {
    GlobalAlerts,
  },

  data: () => ({
    valid: true,
    completeRegister: false,
    registerFlightAssistant: {},
    messageStatus: "",
    estados: jsonEstados,
    situacion: jsonSituacion,
    empresa: jsonEmpresa,
    dialog: false,
    dataForm: {
      nombre: "",
      primerApellido: "",
      segundoApellido: "",
      correoElectronico: "",
      curp: "",
      telefonoContacto: "",
      telefonoMovil: "",
      nombreEmpresa: "",
      situacion: "",
      numeroEmpleado: "",
      avisoPrivacidad: 0,
    },
    rules: {
      required: [(value) => !!value || "El campo es obligatorio."],
      counter: (value) =>
        value.length === this.losnummerLength ||
        `solo se permiten ${this.losnummerLength} caracteres.`,
    },
    avisoPrivacidadFront: 0,
    finadoFront: 0,
    common: {
        activate: false,
        alert:'',
        message:'',
        code: ''
      }
  }),

  mounted() {
    
  },

  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
    currentSession() {
      var session = JSON.parse(this.$localStorage.get("data_user"));
      this.avisoPrivacidadFront = session.sobrecargo.avisoPriv;
      this.finadoFront = session.sobrecargo.finado;
      return session;
    },
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    }
  },

  methods: {
    privacy(option) {
      if (option === "si") {
        this.currentSession.sobrecargo.avisoPriv = true;
        this.dialog = false;
      }
      if (option === "no") {
        this.currentSession.sobrecargo.avisoPriv = false;
        this.dialog = false;
      }
    },
    saveRegister() {
      if (this.$refs.form.validate()) {
        var data = {
          usuario: {
            id: this.currentSession.usuario.id,
            usuario: this.currentSession.usuario.usuario,
          },
          sobrecargo: {
            id: this.currentSession.sobrecargo.id,
            nombre: this.currentSession.sobrecargo.nombre,
            primerApellido: this.currentSession.sobrecargo.primerApellido,
            segundoApellido: this.currentSession.sobrecargo.segundoApellido,
            curp: this.currentSession.sobrecargo.curp,
            correoElectronico: this.currentSession.sobrecargo.correoElectronico,
            telefono: this.currentSession.sobrecargo.telefono,
            telefonoMobil: this.currentSession.sobrecargo.telefonoMobil,
            avisoPriv: this.avisoPrivacidadFront == true ?1:0,
            finado: this.finadoFront == true ?1:0,
            pais:this.currentSession.sobrecargo.pais,
            extranjero:this.currentSession.sobrecargo.extranjero,
            calle:this.currentSession.sobrecargo.calle,
            numExt:this.currentSession.sobrecargo.numExt,
            numInt:this.currentSession.sobrecargo.numInt,
            colonia:this.currentSession.sobrecargo.colonia,
            codigoPostal:this.currentSession.sobrecargo.codigoPostal,
            ciudad:this.currentSession.sobrecargo.ciudad,
            estado:this.currentSession.sobrecargo.estado
          },
          empresa: {
            empresa: this.currentSession.empresa.empresa,
            numeroEmpleado: this.currentSession.empresa.numeroEmpleado,
            situacion: this.currentSession.empresa.situacion,
          },
          solicitud: {
            id: this.currentSession.solicitud.id,
            estatus: this.currentSession.solicitud.estatus,
            folio: null,
          }
        };
        wsFlightAttendant.updateFlightAssistant(data).then((response) => {
            if(response.status == 200){
              switch(response.data.codigo){
                case "1000":
                  localStorage.removeItem("user");
                  localStorage.removeItem("data_user");
                  localStorage.setItem("user", JSON.stringify(response.data));
                  localStorage.setItem("data_user", JSON.stringify(response.data));
                  this.common.activate = true;
                  this.common.alert = 'info';
                  this.common.message = 'Los datos han sido actualizados satisfactoriamente.';
                  this.common.code = '0';
                  this.avisoPrivacidadFront = response.data.sobrecargo.avisoPriv;
                  this.finadoFront = response.data.sobrecargo.finado;
                  break;
                case "2":
                  this.common.activate = true;
                  this.common.alert = 'info';
                  this.common.message = response.data.mensaje;
                  this.common.code = '0';
                  break;
                case "-1":
                  this.common.activate = true;
                  this.common.alert = 'warning';
                  this.common.message = 'En estos momentos el sistema se encuentra en mantenimiento, pronto estaremos de regreso.';
                  this.common.code = '0';
                  localStorage.removeItem("ipAddress");
                  localStorage.removeItem("estatus");
                  localStorage.removeItem("folio");
                  localStorage.removeItem("token");
                  localStorage.removeItem("user");
                  localStorage.removeItem("data_user");
                  localStorage.removeItem("data_curp");
                  localStorage.removeItem("data_pasaporte");
                  localStorage.removeItem("data_credencial");
                  localStorage.removeItem("data_ine");
                  localStorage.removeItem("data_assa");
                  this.$router.push("/");
                  break;
                case "100":
                  this.common.activate = true;
                  this.common.alert = 'warning';
                  this.common.message = 'Enviar un correo electrónico a sigema@assa.mx. Por favor incluir los siguientes datos: correo electrónico, curp y el siguiente codigo DG-100, gracias.';
                  this.common.code = '0';
                  break;
              }
            } else {
              this.common.activate = true;
              this.common.alert = 'warning';
              this.common.message = 'En estos momentos el sistema se encuentra en mantenimiento, pronto estaremos de regreso.';
              this.common.code = '0';
            }
          },
          (error) => {
            if (error.response) {
              // La respuesta fue hecha y el servidor respondió con un código de estado, que esta fuera del rango de 2xx
              if(error.response.status == 403){
                this.common.activate = true;
                this.common.alert = 'warning';
                this.common.message = 'En estos momentos el sistema se encuentra en mantenimiento, pronto estaremos de regreso.';
                this.common.code = '-1';
                localStorage.removeItem("ipAddress");
                localStorage.removeItem("estatus");
                localStorage.removeItem("folio");
                localStorage.removeItem("token");
                localStorage.removeItem("user");
                localStorage.removeItem("data_user");
                localStorage.removeItem("data_curp");
                localStorage.removeItem("data_pasaporte");
                localStorage.removeItem("data_credencial");
                localStorage.removeItem("data_ine");
                localStorage.removeItem("data_assa");
                this.$router.push("/");
              } else {
                this.common.activate = true;
                this.common.alert = 'warning';
                this.common.message = 'En estos momentos el sistema se encuentra en mantenimiento, pronto estaremos de regreso.';
                this.common.code = '-1';
              }
              console.log(error.response.data);
              console.log(error.response.status);
              console.log(error.response.headers);
            } else if (error.request) {
              // La petición fue hecha pero no se recibió respuesta
              // `error.request` es una instancia de XMLHttpRequest en el navegador y una instancia de
              // http.ClientRequest en node.js
              console.log('Error.Request');
              console.log(error.request);
              this.common.activate = true;
              this.common.alert = 'warning';
              this.common.message = 'En estos momentos el sistema se encuentra en mantenimiento, pronto estaremos de regreso.';
              this.common.code = '-2';
            } else {
              // Algo paso al preparar la petición que lanzo un Error
              console.log('Error');
              console.log(error.message);
              this.common.activate = true;
              this.common.alert = 'warning';
              this.common.message = 'En estos momentos el sistema se encuentra en mantenimiento, pronto estaremos de regreso.';
              this.common.code = '-3';
            }
          }
        );
      }
    },
  },
};
</script>