<template>
  <v-container>
    <v-row class="mb-6">
      <v-col cols class="d-flex justify-center">
        <h2 class="display-3">
          {{ meta.appName }}
          <span class="blue-grey--text text--lighten-2">{{
            meta.appVersion
          }}</span>
        </h2>
      </v-col>
    </v-row>
    <v-row class="d-flex justify-center">
      <v-col col xl="6" md="6" sm="12">
        <h2 class="display-1 text-center">R E G I S T R O</h2>
        <hr class="blue darken-4 mt-2 mb-6" />
        <p class="text-justify">
          Compañero(a) sobrecargo de <strong>Mexicana de Aviación</strong> y
          <strong>Click</strong>, es de suma importancia que actualices tus
          datos o te registres a través del <strong>SIGEMA</strong>. Si no te
          has registrado, presiona el siguiente botón de color rojo.
        </p>
        <v-btn
          :to="{ name: 'Register' }"
          block
          class="text-none mb-4 white--text"
          style="font-size: 20px"
          color="red darken-4"
          size="x-large"
          height="72"
          min-width="164"
          variant="flat"
        >
          <v-icon left>mdi-newspaper-check</v-icon>
          Registrarse
        </v-btn>
      </v-col>
    </v-row>
    <v-row class="d-flex justify-center">
      <v-col col xl="6" md="6" sm="12">
        <h2 class="display-1">Iniciar Sesión</h2>
        <hr class="blue darken-4 mt-2 mb-6" />
        <p class="text-justify">
          Una vez registrado, ingresa tu correo y contraseña para acceder al
          sistema.
        </p>
        <GlobalAlerts
          v-if="this.common.activate === true"
          style="text-align: justify"
          :cfgColorBg="
            this.common.alert == 'warning'
              ? 'amber darken-3'
              : 'light-green darken-2'
          "
          :cfgIcon="
            this.common.alert == 'warning'
              ? 'mdi-alert-outline'
              : 'mdi-information-outline'
          "
          cfgProminent="true"
          :cfgMessage="common.message"
        />

        <v-form ref="form" v-model="valid" lazy-validation>
          <v-text-field
            filled
            label="Correo electrónico: "
            v-model="user.username"
            :rules="emailRule"
            required
          />
          <v-text-field
            filled
            label="Contraseña"
            required
            v-model="user.password"
            :rules="contraseniaRule"
            :append-icon="showpass ? 'mdi-eye' : 'mdi-eye-off'"
            :type="showpass ? 'text' : 'password'"
            @click:append="showpass = !showpass"
            hint="Coloca al menos 8 caracteres"
            counter
          />
        </v-form>
      </v-col>
    </v-row>

    <v-row class="d-flex justify-center">
      <v-col col xl="6" md="6" sm="12">
        <v-row class="d-flex justify-center">
          <v-col cols="12">
            <v-btn
              :loading="loading"
              x-large
              color="indigo darken-4"
              size="x-large"
              height="72"
              min-width="164"
              block
              dark
              @click="accessLogin()"
              class="strong float-right"
            >
              <v-icon left> mdi-login </v-icon>
              INGRESAR
            </v-btn>
          </v-col>
        </v-row>
        <v-row class="d-flex text-center">
          <v-col cols="12">
            <v-btn
              :to="{ name: 'PasswordReset' }"
              text
              color="blue darken-2 ma-4"
              style="font-size: 11px"
            >
              ¿Olvidaste tus datos de acceso?
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row class="d-flex justify-center">
      <v-col col xl="6" md="6" sm="12">
        <hr class="blue darken-4 mt-2 mb-6" />
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
//Componentes
import GlobalAlerts from "@/components/global/_Alerts.vue";
//Models
import User from "@/models/user";
export default {
  name: "Login",
  components: {
    GlobalAlerts,
  },
  data() {
    return {
      user: new User("", ""),
      loader: null,
      loading: false,
      message: "",
      valid: true,
      showpass: false,
      messageStatus: "",
      accessOk: {},
      validateForm: false,
      common: {
        activate: false,
        alert: "",
        message: "",
        code: "",
      },

      meta: {
        appName: process.env.VUE_APP_NAME,
        appVersion: process.env.VUE_APP_VERSION,
      },

      rules: {
        email: [
          (v) => !!v || "El correo electrónico obligatorio",
          (v) => /.+@.+/.test(v) || "Con es un correo valido",
        ],
        password: [(v) => !!v || "La contraseña es obligatoria."],
        required: [(value) => !!value || "El campo es obligatorio."],
      },
      contraseniaRule: [
        (v) =>
          !!v.trim() ||
          "Este campo es requerido, captura el dato correspondiente.",
      ],
      emailRule: [
        (v) =>
          !!v.trim() ||
          "Este campo es requerido, captura el dato correspondiente.",
        (v) =>
          /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()\\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            v
          ) || "E-mail no valido",
      ],
    };
  },

  watch: {
    loader() {
      const l = this.loader;
      this[l] = !this[l];
      setTimeout(() => (this[l] = false), 2000);
      this.loader = null;
    },
  },

  mounted() {
    localStorage.removeItem("ipAddress");
    localStorage.removeItem("estatus");
    localStorage.removeItem("folio");
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    localStorage.removeItem("data_user");
    localStorage.removeItem("data_curp");
    localStorage.removeItem("data_pasaporte");
    localStorage.removeItem("data_credencial");
    localStorage.removeItem("data_ine");
    localStorage.removeItem("data_assa");
    //document.title = "SIGEMA :: ASSA";
    document.title = `${this.meta.appName} | ASSA`;
  },

  /*
  created() {
    if (this.loggedIn) {
      this.$router.push("/flight-attendant");
    }
  },
  */
  created(){
    /*
    console.log('Authenticado = '+this.$store.state.auth.status.loggedIn);
    console.log('User',this.$store.state.auth.user);
    */

  },

  methods: {
    accessLogin() {
      this.$refs.form.validate();
      if (
        this.user.password != null &&
        this.user.password != "" &&
        this.user.username != null &&
        this.user.username != ""
      ) {
        this.validateForm = true;
      }
      if (this.validateForm) {
        this.$store.dispatch("auth/login", this.user).then(
          (response) => {
            console.log('LOGIN_RESPONSE - ENTRO');
            switch (response.codigo) {
              case "01":
                break;
              case "02":
                break;
              case "0":
                this.common.activate = true;
                this.common.alert = "warning";
                this.common.message =
                  "Validar tus claves de acceso. En caso de no estar registrado realizarlo en la sección de registro. En caso de no recordar tus claves de acceso ir la sección de ¿Olvidaste tus datos de acceso?";
                this.common.code = "-1";
                break;
              case "1000":
                this.messageStatus = "ValidationOk";
                this.$router.push("/dashboard");
                break;
              case "100":
                this.common.activate = true;
                this.common.alert = "warning";
                this.common.message =
                  "Debes de capturar tus datos para poder ingresar al sistema.";
                this.common.code = "-1";
                break;
              default:
                this.messageStatus = "OperationInvalid";
                break;
            }
          },
          (error) => {
            if (error.response) {
              // La respuesta fue hecha y el servidor respondió con un código de estado, que esta fuera del rango de 2xx
              if (error.response.status == 403) {
                this.common.activate = true;
                this.common.alert = "warning";
                this.common.message =
                  "En estos momentos el sistema se encuentra en mantenimiento, pronto estaremos de regreso.";
                this.common.code = "-1";
              } else {
                this.common.activate = true;
                this.common.alert = "warning";
                this.common.message =
                  "En estos momentos el sistema se encuentra en mantenimiento, pronto estaremos de regreso.";
                this.common.code = "-1";
              }
              console.log(error.response.data);
              console.log(error.response.status);
              console.log(error.response.headers);
            } else if (error.request) {
              // La petición fue hecha pero no se recibió respuesta
              // `error.request` es una instancia de XMLHttpRequest en el navegador y una instancia de
              // http.ClientRequest en node.js
              console.log("Error.Request");
              console.log(error.request);
              this.common.activate = true;
              this.common.alert = "warning";
              this.common.message =
                "En estos momentos el sistema se encuentra en mantenimiento, pronto estaremos de regreso.";
              this.common.code = "-2";
            } else {
              // Algo paso al preparar la petición que lanzo un Error
              console.log("Error");
              console.log(error.message);
              this.common.activate = true;
              this.common.alert = "warning";
              this.common.message =
                "En estos momentos el sistema se encuentra en mantenimiento, pronto estaremos de regreso.";
              this.common.code = "-3";
            }
          }
        );
      }
    },
  },
};
</script>
